import React from 'react';
import { withTooltip } from 'react-tippy';
import ProbeSelection from './ProbeSelection.jsx';

/**
 * PROPS
 * slaveList - [obj] - {'AMS':'1.1.1.1', 'LON': '2.2.2.2', ...}
 * agSlaves - [] -
 */
class AutoGroupSettings extends React.PureComponent {
    getSlider = () => {
        const { agCount } = this.props;
        const upperLimit = 10;
        return (
            <div className='range-slider'>
                <span
                    className='fa fa-minus'
                    onClick={e => this.props.setStateByKey((prevstate) => {
                        let agCount = prevstate.agCount - 1;
                        agCount = agCount < 1 ? 1 : agCount;
                        return { agCount };
                    })}
                />
                <input
                    type='range'
                    name='points'
                    min={1}
                    max={upperLimit}
                    onChange={e => this.props.setStateByKey('agCount', e.target.value)}
                    value={agCount}
                />
                <span
                    className='fa fa-plus'
                    onClick={e => this.props.setStateByKey((prevstate) => {
                        let agCount = prevstate.agCount + 1;
                        agCount = agCount > upperLimit ? upperLimit : agCount;
                        return { agCount };
                    })}
                />
            </div>
        );
    }

    getProbeSelection = () => (
        <div className='slave-selection'>
            <ProbeSelection
                tab='monitoring'
                setSelectedProbes={this.setSelectedProbes}
                probesList={this.setProbesSelection()}
                slaveSources={this.props.slaveSources}
            />
        </div>
    );

    /**
     *  @param data - [obj] - {'probe1':false, 'probe2':true, 'probe3'; false...}
     *  output -> ['probe2'...]
     */
    setSelectedProbes = data => this.props.setStateByKey('agSlaves', Object.keys(data).filter(probe => data[probe]));

    /** *
     * @param data - [obj] - {{'probe1':'1.1.1.1', 'probe2':'2.2.2.2', 'probe3'; '3.3.3.3'}}
     * output -> {{'probe1':'false', 'probe2':'true', 'probe3'; 'false'}} - bool is decided on IP's probes in config
     */
    setProbesSelection = (selectedProbes = this.props.agSlaves) => {
        const data = this.props.slaveList;
        const outputFormat = {};
        const probeKeys = Object.keys(data);
        probeKeys.forEach((key) => {
            outputFormat[key] = selectedProbes.includes(key);
        });
        return outputFormat;
    }

    render() {
        const InfoIcon = () => (<i className='fa fa-info-circle' />);
        const tooltipOptions = {
            style: { marginLeft: '20px' },
            theme: 'dark',
            position: 'right',
            animation: 'perspective',
        };
        const networkTool = { ...tooltipOptions };
        networkTool.title = 'Add a network via subnet (1.1.1.1/24)  or as( AS1234 ).';
        const NetworkTooltip = withTooltip(InfoIcon, networkTool);
        const sliderTool = { ...tooltipOptions };
        sliderTool.title = 'Backend automatically selects \'X\' IPs from the network. ' +
            'If IP goes dead, system swaps that ip for a new one.';
        const SliderTooltip = withTooltip(InfoIcon, sliderTool);
        return (
            <div className='auto-group-settings'>
                <p />
                <div>
                    <div className='auto-group-box' />
                    <div className='auto-group-box'>
                        <div className='right-space'>
                            <span>
                                SUBNET/AS
                                <NetworkTooltip />
                            </span>
                            <input
                                type='text'
                                placeholder='1.1.1.1/24 or AS1234'
                                value={this.props.agNetwork}
                                onChange={e => this.props.setStateByKey('agNetwork', e.target.value.toUpperCase())}
                            />
                        </div>
                        <div>
                            <span>
                                { 'Number of monitored IPs [' + this.props.agCount + ']'}
                                <SliderTooltip />
                            </span>
                            {this.getSlider()}
                        </div>
                    </div>
                    <div className='auto-group-box'>
                        {this.getProbeSelection()}
                    </div>
                </div>

            </div>

        );
    }
}

export default AutoGroupSettings;
