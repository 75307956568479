import React from 'react';
import Utils from '../../js/utils.js';

class LicenseError extends React.PureComponent {
    render() {
        let prompt;
        return (
            <div id='mother'>
                <div className={`invalid-license ${this.props.isMobile ? 'mobile' : ''}`}>
                    <div className='wrap'>
                        <img alt='cocopacket-logo' src='/less/img/cocopacket-logo_inverse.svg' />
                        <div className='box'>
                            {!this.props.license.isValid
                                && (
                                    <p>
                                    We are sorry, but it seems like the license is no longer valid.
                                        <br />
                                        <br />
                                    Contact our support team
                                        <br />
                                        <br />
                                    </p>
                                )
                            }
                            {this.props.license.isExceeded
                                && (
                                    <p>
                                    We are sorry, but it seems like the license has exceeded the monitoring limit.
                                        <br />
                                        <br />
                                    Contact our support team
                                        <br />
                                        <br />
                                    </p>
                                )
                            }
                            <a href='mailto:support@cocopacket.com' className='link'>support@cocopacket.com</a>
                            <br />
                            <div className='controls'>
                                <a onClick={Utils.handleLogout}>Log out</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LicenseError;
