/* global $:true, Chart:true */
import React from 'react';
// highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts-multicolor-series';

import Utils from '../../js/utils.js';
import Loader from './loader.jsx';
import '../../js/chart.js';

Exporting(Highcharts);

class Graph extends React.Component {
    constructor(props) {
        super(props);
        this.chart = null;
        this.loader = true;
    }

    getChartConfig = () => {
        const { props } = this;
        const {
            isUTC, chartWidth, originalData, graphType, isMobile, zoomLevel, stats,
        } = props;
        const isBasic = graphType === 'basic';
        const { endPoint, epoch } = props.ipDetails;
        const customKeys = { epoch, start: (endPoint - epoch) * 1000, color: props.theme };
        const data = { ...props.graphData, ...customKeys };
        if (zoomLevel === 0) {
            data.yaxis = isBasic ? data.yaxis * 1.4 : null;
        }
        const getDataFunction = isBasic ? 'getBasicChartConfig' : 'getAdvancedChartConfig';
        let chartConfig = {};
        if (isMobile) {
            chartConfig = Chart[getDataFunction + 'Mobile'](data);
        } else {
            chartConfig = Chart[getDataFunction](data, this.zoomSelection, isUTC, originalData, chartWidth, stats.count);
        }
        if (this.props.noZoom) {
            chartConfig.chart.zoomType = 'none';
        }
        return chartConfig;
    }

    zoomSelection = (event) => {
        let from = event.xAxis[0].min / 1000;
        from = Number(from.toFixed(0));
        let to = event.xAxis[0].max / 1000;
        to = Number(to.toFixed(0));
        this.props.setEpochFromZoom(from, to);
        return false;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.graphData != nextProps.graphData ||
            this.props.isUTC != nextProps.isUTC ||
            this.props.zoomLevel != nextProps.zoomLevel) {
            this.loader = false;
            return true;
        }
        if (this.props.ipDetails != nextProps.ipDetails ||
            this.props.selectedIP != nextProps.selectedIP ||
            this.props.theme != nextProps.theme) {
            this.loader = true;
            return true;
        }
        return false;
    }

    componentDidUpdate() {
        if (!this.loader && Utils.isNotSet(this.props.graphData.data)) {
            let { prompt } = this.props.graphData;
            prompt = typeof prompt === 'string' && prompt.length > 0 ? prompt.toUpperCase() : 'NO DATA';
            this.chart.showLoading(prompt);
        }
    }

    render() {
        return (
            <div className='flex-row'>
                <div ref='chartNode' className='chart-container chart-container-load'>
                    {this.loader && <Loader /> }
                    {!this.loader &&
                        <HighchartsReact
                            key={new Date().getTime()}
                            highcharts={Highcharts}
                            options={this.getChartConfig()}
                            callback={(chart) => {
                                this.chart = chart;
                                this.props.setChartInstance('chart', chart);
                            }}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default Graph;
