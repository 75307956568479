/* global $:true */
import React from 'react';
import update from 'immutability-helper';
import Filter from './filtering.js';
import Utils from '../../../js/utils.js';
import IP from '../../sidePanel/sidebar/ip.jsx';
import SearchInput from './searchInput.jsx';
import SearchResultsWrap from './searchResultsWrap.jsx';

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.moveToQuickLimit = 101;
    }

    getInitialState = () => ({
        allData: [],
        results: [],
        searchInput: '',
        inputValOnClose: '',
        showOptions: false,
        liveIPs: true,
        inputValues: {},
        lastSelection: { id: -1, bool: false },
        selectAll: false,
        selectedCount: 0,
    });

    // -------------------
    offClick = () => {
        const scope = this;
        $('.main-panel, #sidebar').off('click');
        if (this.state.showOptions) {
            this.bindEscSearch();
            $('.main-panel, #sidebar').on('click', () => {
                scope.closeSearch();
            });
        }
    }

    bindEscSearch = () => {
        const scope = this;
        $(document).on('keydown', (e) => {
            if (e.keyCode === 27) { // ESC
                scope.closeSearch();
            }
        });
    }

    deleteHistory = () => this.setState({ inputValOnClose: '', searchInput: '', results: [] })

    setLocalState = data => this.setState({ ...data })

    setResults = inputValue => this.setState({ results: this.getResults(inputValue) });

    getResults = (inputValue, liveIPs = this.state.liveIPs) => {
        let data = [];
        if (inputValue) {
            const subRegex = Utils.getSubnetRegex();
            const { allData } = this.state;
            if (subRegex.test(inputValue)) {
                data = Filter.filter('bySubnet', inputValue, allData);
            } else if (inputValue.startsWith('as')) {
                data = Filter.filter('byAS', inputValue, allData);
            } else {
                data = Filter.filter('byString', inputValue, allData);
            }
        }
        if (liveIPs) {
            data = Filter.filterLiveIPs(data, this.props.colorList);
        }
        return data;
    }

    changeLiveIPs = () => this.setState((prevState) => {
        const decision = !prevState.liveIPs;
        const data = decision ? Filter.filterLiveIPs(prevState.results, this.props.colorList) : this.getResults(prevState.searchInput, decision);
        return {
            liveIPs: decision,
            selectedCount: prevState.selectedCount > 0 ? prevState.selectedCount : 0,
            results: data,
        };
    });

    closeSearch = () => {
        $(document).off('keydown');
        const val = this.state.searchInput;
        this.setState((prevState) => {
            const obj = this.setInputs(prevState, false);
            return {
                searchInput: '',
                showOptions: false,
                detailID: NaN,
                inputValOnClose: Utils.isSet(val) ? val : prevState.inputValOnClose,
                lastSelection: { id: -1, bool: false },
                selectAll: false,
                selectedCount: 0,
                ...obj,
            };
        });
    }

    markAllInputs = () => this.setState((prevState) => {
        const bool = !prevState.selectAll;
        const obj = this.setInputs(prevState, bool);
        return { selectAll: bool, ...obj };
    });

    setInputs = (prevState, bool) => {
        const obj = Object.assign({}, prevState.inputValues);
        const result = Object.assign({}, prevState.results);
        const liveOnly = prevState.liveIPs;
        let count = 0;
        Object.keys(result).forEach((key) => {
            const { ip } = result[key];
            const ipLoss = this.props.colorList[ip];
            if ((liveOnly && ipLoss.AVERAGE < 51) || !liveOnly) {
                obj[ip] = bool;
                count += 1;
            }
        });
        return {
            inputValues: obj,
            selectedCount: bool ? count : 0,
        };
    }

    setSearchItems = (props) => {
        let data = props.config;
        if (Utils.isNotSet(data)) {
            return [];
        }
        data = data.ping.ips;
        const options = [];
        const inputValues = {};
        for (const ip in data) {
            const opt = {};
            // set IP
            opt.ip = ip;
            // set title
            let title = data[ip].desc;
            if (title == undefined) {
                title = data[ip];
            }
            opt.alias = title;
            // set slaves
            const slArr = props.config.ping.ips[ip].slaves;
            if (Utils.isSet(slArr)) {
                opt.slaves = slArr.toString();
            }
            // set category and AS
            const { cat } = data[ip];
            opt.cat = Utils.isSet(cat) ? typeof cat === 'string' ? cat : cat[0] : 'IPS';
            opt.as = data[ip].as;

            // push created structure in searchable items array
            options.push(opt);
            inputValues[ip] = false; // by default all inputs are unchecked
        }

        this.setState({
            allData: options,
            inputValues,
        });
    }

    inputClick = (e, id, list) => {
        e.preventDefault();
        e.stopPropagation();
        const inputs = this.state.inputValues;
        const last = this.state.lastSelection;
        let count = this.state.selectedCount;
        if (last.id >= 0 && e.shiftKey) {
            let from = last.id;
            let to = id;
            if (from > to) {
                const temp = from;
                from = to;
                to = temp;
            }
            const obj = Object.assign({}, inputs);
            for (let i = from; i <= to; i++) {
                const key = list[i].ip;
                obj[key] = last.bool;
                count += last.bool ? 1 : -1;
            }
            // count -1 is (-1) because wa are including both ends, but one end was alredy counted
            // with a initial single click
            this.setState({
                inputValues: obj,
                lastSelection: { id: -1, bool: false },
                selectedCount: count - 1,
            });
        } else {
            const key = list[id].ip;
            const bool = inputs[key];
            count += !bool ? 1 : -1;
            const newState = update(this.state, {
                inputValues: { [key]: { $set: !bool } },
                lastSelection: { $set: { id, bool: !bool } },
                selectedCount: { $set: count },
            });
            this.setState(newState);
        }
    }

    moveToQuicklist = () => {
        let data = this.state.results;
        const count = this.state.selectedCount;
        if (count > 0 && count < data.length) { // inputs are selected
            const inputs = this.state.inputValues;
            const selection = Object.keys(inputs).filter(key => inputs[key]); // filter out selected IPs
            data = data.filter(i => selection.includes(i.ip)); // filter out only desired IPs
        }
        let keys = Object.keys(data);
        if (data.length > 0 && data.length > this.moveToQuickLimit) {
            keys = keys.slice(0, this.moveToQuickLimit);
        }
        const scope = this;
        const ipArr = [];
        const sortedKeys = keys.sort((a, b) => {
            a = data[a].alias.toString().toLowerCase();
            b = data[b].alias.toString().toLowerCase();
            return -(a < b) || +(a != b);
        });
        const dataArr = [];
        for (const ip in sortedKeys) {
            const server = data[sortedKeys[ip]];
            dataArr.push({
                ip: server.ip,
                title: server.alias,
                isfav: false,
            });
            ipArr.push(
                <IP
                    key={ip}
                    ip={server.ip}
                    title={server.alias}
                    color={scope.getIpColor(this.props.colorList[server.ip])}
                    defaultColor={Utils.getLossColor(undefined)}
                    slave={undefined}
                    updateSelectedIp={scope.props.updateSelectedIp}
                    addValToQuickList={scope.props.addValToQuickList}
                />,
            );
        }
        const dataRecord = { data: { I_P_S: dataArr } };
        this.props.addValToQuickList(
            {
                isIp: false, name: 'SEARCH', data: dataRecord, 'data-ip': ipArr,
            },
            true,
        );
        this.closeSearch();
    }

    getIpColor = (arr) => {
        const loss = arr == undefined ? undefined : arr.AVERAGE;
        const color = Utils.getLossColor(loss);
        return color;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.config != nextProps.config) {
            this.setSearchItems(nextProps);
        }
    }

    componentDidMount() {
        this.setSearchItems(this.props);
    }

    render() {
        const show = this.state.showOptions;
        const showClose = show && !this.props.isMobile;
        if (show && !this.props.isMobile) {
            this.offClick();
        }
        return (
            <div id='search' className='search-wrap'>
                <SearchInput
                    isMobile={this.props.isMobile}
                    results={this.state.results}
                    searchInput={this.state.searchInput}
                    setResults={this.setResults}
                    setLocalState={this.setLocalState}
                />
                {show &&
                    <SearchResultsWrap
                        {...this.state}
                        updateSelectedIp={this.props.updateSelectedIp}
                        isMobile={this.props.isMobile}
                        colorList={this.props.colorList}
                        theme={this.props.theme}
                        closeSearch={this.closeSearch}
                        inputClick={this.inputClick}
                        setLocalState={this.setLocalState}
                        deleteHistory={this.deleteHistory}
                        changeLiveIPs={this.changeLiveIPs}
                        moveToQuicklist={this.moveToQuicklist}
                        getIpColor={this.getIpColor}
                        setResults={this.setResults}
                        markAllInputs={this.markAllInputs}
                    />
                }
            </div>
        );
    }
}

export default Search;
