import React from 'react';
import GraphProbe from '../probes.jsx';
import EpochPicker from '../epochPicker.jsx';

const GraphHeaderMobile = (props) => {
    const { probesList } = props;
    return (
        <div className='header mobile'>
            <div className='top-part-header mobile'>
                <div className='top-line-block'>
                    <a className='home' href='/'>
                        <img alt='cocopacket-logo' id='icon-logo' src='/less/img/cocopacket-logo_inverse.svg' />
                    </a>
                </div>
                <div className='control-block'>
                    {props.getMobileSearchBox(props)}
                </div>
            </div>
            <GraphProbe
                activeProbe={props.ipDetails.probe}
                selectedIP={props.selectedIP}
                setActiveProbe={props.setActiveProbe}
                probesList={probesList}
                handleDetailSwitch={props.handleDetailSwitch}
                colorList={props.colorList}
                isMobile={props.isMobile}
                isNarrow={props.isNarrow}
            />
            <EpochPicker
                epoch={props.ipDetails.epoch}
                setEpochWithEndpoint={props.setEpochWithEndpoint}
                isMobile={props.isMobile}
            />
        </div>
    );
};

export default GraphHeaderMobile;
