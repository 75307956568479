import Highcharts from 'highcharts';
import Utils from './utils.js';

const Chart = window.Chart = {
    colorField: ['#00B851', '#009DDB', '#025AFF', '#5F04FF', '#7F01FF', '#DD02FF', '#F54949'],
    tooltipVal: true,

    getPlotLinesBasic(data) {
        if (!Utils.isSet(data)) {
            return [];
        }
        const start = data[0];
        const end = data[data.length - 1];
        if (Utils.isSet(start) && Utils.isSet(end)) {
            return this.createPlotLines(start.x, end.x);
        }
        return [];
    },

    hexToRgbA(hex, opacity = 1) {
        let c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = `0x${c.join('')}`;
            return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
        }
        throw new Error('Bad Hex');
    },

    getPlotLinesAdvanced(dataOriginal) {
        if (!Utils.isSet(dataOriginal)) {
            return [];
        }
        const { data } = dataOriginal[0];
        const start = data[0];
        const end = data[data.length - 1];
        if (Utils.isSet(start) && Utils.isSet(end)) {
            return this.createPlotLines(start[0], end[0]);
        }
        return [];
    },

    getPlotBands(data) {
        let bands = [];
        if (data != undefined) {
            bands = [{
                color: 'rgba(255,204,204,0.6)',
                from: data.to * 1000,
                to: data.from * 1000,
            }];
        }
        return bands;
    },

    getTick(diff) {
        const hour = 3600;
        let out = 0;
        if (diff <= 1 * hour) {
            out = 1 * 60;
        } else if (diff <= 3 * hour) {
            out = 5 * 60;
        } else if (diff <= 7 * hour) {
            out = 10 * 60;
        } else if (diff <= 30 * hour) {
            out = 3 * hour;
        } else if (diff <= 3 * 24 * hour) {
            out = 6 * hour;
        } else if (diff <= 15 * 24 * hour) {
            out = 12 * hour;
        } else {
            out = 7 * 24 * hour;
        }
        return out * 1000;
    },

    createPlotLines(start, end) {
        const time = new Date(start);
        const minutesTilMidnight = (60 - time.getMinutes()) + 60 * (24 - time.getHours() - 1);
        let iterator = start + minutesTilMidnight * 60 * 1000;
        const timestamps = [];
        while (iterator < end) {
            timestamps.push({
                color: 'rgba(255,255,200,0.5)',
                width: 1,
                value: iterator,
            });
            iterator += 24 * 60 * 60 * 1000;
        }
        return timestamps;
    },

    getTooltip(tooltipFormatter, width, count, graphDataset, isUTC = false) {
        return {
            enabled: true,
            useHTML: true,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            followPointer: true,
            shadow: false,
            borderRadius: 5,
            positioner(boxWidth, boxHeight, point) {
                const halfChartWidth = this.chart.chartWidth / 2;
                let tooltipX = point.plotX + (point.plotX > halfChartWidth ? -boxWidth : boxWidth / 2);

                const halfChartHeight = this.chart.chartHeight / 2;
                const tooltipY = point.plotY + (point.plotY > halfChartHeight ? -20 : 0);
                return { x: tooltipX, y: tooltipY };
            },
            formatter() {
                const info = graphDataset[this.x / 1000];
                return tooltipFormatter(this.x, info, count, isUTC);
            }
        };
    },

    getCrossHair() {
        return {
            color: '#EC0000',
            dashStyle: 'solid',
            zIndex: 100,
        };
    },

    getAdvancedChartConfigMobile(data) {
        const chart = this.getAdvancedChartConfig(data);
        chart.tooltip = {enabled: false};
        chart.chart.zoomType = undefined;
        chart.chart.pinchType = '';
        chart.chart.events = undefined;
        chart.xAxis.labels.style = GraphLabelSettingsMobile.style;
        chart.yAxis.labels.style = GraphLabelSettingsMobile.style;
        return chart;
    },

    getAdvancedChartConfig(dataSet, callback, isUTC = false, originalData, width, agrCount) {
        const tick = this.getTick(dataSet.epoch);
        const { data } = dataSet;
        const style = Chart.getGraphLabelSettings(dataSet.color.sideText);
        return {
            credits: { enabled: false },
            chart: {
                backgroundColor: dataSet.color.colorBckgr,
                color: dataSet.color.colorText,
                type: 'column',
                zoomType: 'x',
                height: 400,
                events: {
                    selection: callback,
                },
            },
            title: {
                text: null,
                style: {
                    color: 'black',
                    fontSize: '30px',
                },
            },
            loading: {
                style: {
                    top: '20%',
                    backgroundColor: null,
                    color: 'white',
                    fontSize: '30px',
                },
            },
            time: {
                useUTC: isUTC,
            },
            xAxis: {
                min: dataSet.start,
                type: 'datetime',
                tickInterval: tick,
                dateTimeLabelFormats: style.dateTimeFormats,
                labels: {
                    style: style.style,
                },
                gridLineColor: dataSet.color.colorText,
                gridLineWidth: 1,
                crosshair: this.getCrossHair(),
            },
            yAxis: {
                title: {
                    text: null,
                },
                labels: {
                    style: style.style,
                    formatter() {
                        return `${this.value}ms`;
                    },
                },
                gridLineColor: dataSet.color.colorText,
                gridLineWidth: 1,
                max: dataSet.yaxis,
                min: 0,
            },
            legend: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
            tooltip: (Utils.isSet(agrCount)) ? this.getTooltip(LatencyTooltipFormatterAdvanced, width, agrCount.count, originalData, isUTC) : {},
            plotOptions: {
                column: {
                    borderWidth: 0,
                    stacking: 'normal',
                    pointPadding: 0,
                    groupPadding: 0,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                },
            },
            series: Utils.isNotSet(data) ? [] : [
                ...data,
                {
                    type: 'line',
                    step: 'center',
                    color: dataSet.color.simpleLine,
                    lineWidth: 1,
                    marker: {
                        enabled: false,
                    },
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                    turboThreshold: (dataSet.lineData.length + 1),
                    data: dataSet.lineData,
                    animation: false,
                },
            ],
        };
    },

    getGraphLabelSettings(color) {
        return {
            style: {
                fontSize: '14px',
                fontWeight: 'normal',
                fontFamily: 'Helvetica Neue',
                color,
            },

            dateTimeFormats: {
                minute: '%H:%M',
                hour: '%H:%M',
                day: '%A <br/> %e.%b',
                week: '%A <br/> %e.%b',
                year: '%B',
            },
        };
    },

    getBasicChartConfigMobile(data) {
        const chart = this.getBasicChartConfig(data);
        chart.chart.zoomType = undefined;
        chart.tooltip = { enabled: false };
        chart.chart.pinchType = '';
        chart.chart.events = undefined;
        chart.xAxis.labels.style = GraphLabelSettingsMobile.style;
        chart.yAxis.labels.style = GraphLabelSettingsMobile.style;
        return chart;
    },

    getBasicChartConfig(dataSet, callback, isUTC = false, originalData, width, agrCount) {
        const tick = this.getTick(dataSet.epoch);
        const { data } = dataSet;
        const style = Chart.getGraphLabelSettings(dataSet.color.sideText);
        return {
            credits: { enabled: false },
            chart: {
                backgroundColor: dataSet.color.colorBckgr,
                color: dataSet.color.colorText,
                height: 400,
                zoomType: 'x',
                events: {
                    selection: callback,
                },
            },
            loading: {
                style: {
                    top: '20%',
                    backgroundColor: null,
                    color: 'white',
                    fontSize: '30px',
                },
            },
            title: {
                text: null,
                style: {
                    color: dataSet.color.colorText,
                    fontSize: '30px',
                },
            },
            colors: ['#19e880'],
            time: {
                useUTC: isUTC,
            },
            xAxis: {
                min: dataSet.start,
                type: 'datetime',
                tickInterval: tick,
                dateTimeLabelFormats: style.dateTimeFormats,
                labels: {
                    style: style.style,
                },
                gridLineColor: dataSet.color.colorText,
                gridLineWidth: 2,
                crosshair: this.getCrossHair(),
            },
            yAxis: {
                title: {
                    text: null,
                },
                labels: {
                    style: style.style,
                    formatter() {
                        return `${this.value}ms`;
                    },
                },
                gridLineColor: dataSet.color.colorText,
                min: 0,
                max: dataSet.yaxis,
            },
            legend: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
            tooltip: (Utils.isSet(agrCount)) ? this.getTooltip(LatencyTooltipFormatterBasic, width, agrCount.count, originalData, isUTC) : {},
            plotOptions: {
                column: {
                    stacking: null,
                },
                series: {
                    marker: {
                        enabled: true,
                    },
                },
                animation: false,
            },
            series: Utils.isNotSet(data) ? [] : [{
                type: 'coloredline',
                marker: {
                    enabled: false,
                },
                name: 'Latency',
                states: {
                    hover: {
                        enabled: false,
                    },
                },
                data,
                lineWidth: 1,
                turboThreshold: (data.length + 1),
                animation: false,
            }],
        };
    },
};

var GraphLabelSettingsMobile = {
    style: {
        fontSize: '10px',
        fontWeight: 'normal',
        fontFamily: 'Helvetica Neue',
        color: '#BBBDBE',
    },
};

const BytesLabelFormatter = function () {
    return Utils.formatBytes(this.value);
};

const BpsLabelFormatter = function () {
    return Utils.formatValue(this.value, 'bps');
};

const RequestsLabelFormatter = function () {
    return Utils.formatValue(this.value, '');
};

const CurrencyLabelFormatter = function () {
    return Utils.formatCurrency(this.value, '$');
};

const NameAndTimeTooltip = function (name, x) {
    return `<b>${name}</b><br/>${Highcharts.dateFormat('%d.%m %H:%M', new Date(x))}`;
};

const BytesTooltipFormatter = function () {
    return `${NameAndTimeTooltip(this.series.name, this.x)}<br>${Utils.formatBytes(this.y)}`;
};

const BpsTooltipFormatter = function () {
    return `${NameAndTimeTooltip(this.series.name, this.x)}<br>${Utils.formatValue(this.y, 'bps')}`;
};

const RequestsTooltipFormatter = function () {
    return `${NameAndTimeTooltip(this.series.name, this.x)}<br>${Utils.formatValue(this.y, '')}`;
};

const CurrencyTooltipFormatter = function () {
    return `${NameAndTimeTooltip(this.series.name, this.x)}<br>${Utils.formatCurrency(this.y, '$')}`;
};

var LatencyTooltipFormatterBasic = function (pointX, info, count, isUTC) {
    if (!Chart.tooltipVal) { return false; }
    const lat = Utils.getPropertyOfObject(['med'], info, 0);
    const loss = Utils.getPropertyOfObject(['lost'], info, 0);
    const time =  Utils.formatTime(pointX, isUTC, true, true)+ ' - ' + Utils.formatTime(pointX+(count*1000), isUTC, false, true);
    return htmlTooltip(lat, loss, time);
};

var LatencyTooltipFormatterAdvanced = function (pointX, info, count, isUTC) {
    if (!Chart.tooltipVal) { return false; }
    const lat = Utils.getPropertyOfObject(['med'], info, 0);
    const loss = Utils.getPropertyOfObject(['loss'], info, 0);
    const time =  Utils.formatTime(pointX, isUTC, true, true)+ ' - ' + Utils.formatTime(pointX+(count*1000), isUTC, false, true);
    return htmlTooltip(lat, loss, time);
};

var htmlTooltip = function (lat, loss, time) {
    const latFixed = Number(lat).toFixed(2);
    const lossFixed = Number(loss).toFixed(2);
    const color = Utils.getLossColor(loss);
    return `${'<div class="tooltip">'
        + '<span><strong>LAT:</strong><span class="val">'}${latFixed}ms</span></span>`
        + `<span><strong>PL:</strong><span class="val" style="color:${color};">${lossFixed}%</span></span>`
        + `<span><strong>${time}</strong></span>`
        + '</div>';
};
