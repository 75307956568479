import React from 'react';
import SearchResults from './searchResults.jsx';
import SearchResultsMobile from './searchResults.mobile.jsx';

class SearchResultsWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({ sortOption: 'ip', sortIsAscend: true });

    changeSortAttr = (val) => {
        if (val != this.state.sortOption) {
            this.setState({ sortOption: val });
        } else {
            this.setState(prev => ({ sortIsAscend: !prev.sortIsAscend }));
        }
    }

    changeIP = (ip) => {
        this.props.updateSelectedIp(ip);
        this.props.closeSearch();
    }

    colorHidden = color =>  (color == '' || color == undefined) ? 'hidden' : '';

    orderData = () => {
        let data = this.props.results;
        if (this.props.isMobile && data.length === 0 && this.props.searchInput === '') {
            data = this.props.allData;
        }
        const sortAttr = this.state.sortOption;
        if (this.state.sortIsAscend) {
            return data.sort((a, b) => -(a[sortAttr] < b[sortAttr]) || +(a[sortAttr] != b[sortAttr]));
        }
        return data.sort((a, b) => -(a[sortAttr] > b[sortAttr]) || +(a[sortAttr] != b[sortAttr]));
    }

    render() {
        const Component = this.props.isMobile ? SearchResultsMobile : SearchResults;
        return (
            <Component
                {...this.props}
                changeSortAttr={this.changeSortAttr}
                changeIP={this.changeIP}
                colorHidden={this.colorHidden}
                orderData={this.orderData}
                threshold={200}
            />
        );
    }
}

export default SearchResultsWrap;
