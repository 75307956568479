import React from 'react';

class Error extends React.PureComponent {
    render() {
        return (
            <div>
                <h2 className='error404'>Ups, something went wrong error 404 (incorrect route).</h2>
                <h3 className='error404 wrong-params-msg'>
                    <p>
                        Parameters in the url might be wrong.
                        <br />
                        End-Points - Epoch must create a valid timestamp value in near past.
                    </p>
                </h3>
                <h3 className='error404 goHome'>
                    <span>
                        <a href='#/'>Go home</a>
                    </span>
                </h3>
            </div>
        );
    }
}

export default Error;
