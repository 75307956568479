import React from 'react';
import Utils from '../../../js/utils';

class SearchResultsMobile extends React.Component {
    getData = () => {
        const entrys = [];
        const list = this.props.orderData();
        const { threshold } = this.props;
        let counter = 0;
        for (let i = 0; i < list.length; i++) {
            if (counter > threshold) { return entrys; }
            const temp = list[i];
            if (Utils.isSet(temp.slaves)) {
                counter += 1;
                const data = {
                    ...temp,
                    alias: Utils.isNotSet(temp.alias) ? temp.ip : temp.alias
                };
                let { slaves } = temp;
                slaves = slaves.length > 20 ? `${slaves.substring(0, 20)}...` : slaves;
                entrys.push(this.createEntry(i, data));
            }
        }
        if (entrys.length === 0) {
            return (<tr key='empty'><td colSpan='2'> No Results </td></tr>);
        }
        return entrys;
    }

    createEntry = (index, data) => {
        const ipLoss = this.props.colorList[data.ip];
        const packetLoss = this.props.getIpColor(ipLoss);
        const style = { fill: packetLoss };
        return (
            <tr key={index} onClick={() => this.props.changeIP(data.ip)}>
                <td className='pl'>
                    <svg className={this.props.colorHidden(packetLoss)}>
                        <circle cx='6' cy='6' r='6' style={style} />
                    </svg>
                </td>
                <td className='alias'>{data.alias}</td>
            </tr>
        );
    }

    render() {
        const { option, changeSortAttr, closeSearch, inputValOnClose, deleteHistory } = this.props;
        return (
            <div className='result'>
                <div className='btn-wrap'>
                    <button type='button' className='btn-close' onClick={closeSearch}>CLOSE SEARCH</button>
                </div>
                { Utils.isSet(inputValOnClose) &&
                <h3>
                    Last Search :
                    {inputValOnClose}
                    <a onClick={deleteHistory} className='fa fa-times' />
                </h3>
                }
                <table cellPadding='0' cellSpacing='0'>
                    <thead>
                        <tr className='head-row'>
                            <th onClick={() => changeSortAttr('packetLoss')} className={option === 'packetLoss' ? 'active' : ''}>
                                PL
                            </th>
                            <th onClick={() => changeSortAttr('alias')} className={option === 'alias' ? 'active' : ''}>
                                ALIAS
                            </th>
                        </tr>
                    </thead>
                    <tbody>{this.getData()}</tbody>
                </table>
            </div>
        );
    }
}

export default SearchResultsMobile;
