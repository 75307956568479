const GetAlertTemplate = (key) => {
    const options = {
        SLACK_TEMP: {
            method: 'POST',
            url: 'https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXXXXXXXXXXXXXXX',
            payload: '{ "text": "*`<*GROUP*>`* - `<*IP*>` - `<*SLAVE*>`   -->   LAT: *`<*LATENCY*>`* ms PL: *`<*LOSS*>`* % \n <*DOMAIN*>/#/detail?ip=<*IP*>&epoch=3600&probe=<*SLAVE*>&graphType=basic"}',
            contentType: 'application/json',
            helper: 'https://api.slack.com/messaging/webhooks',
        },
        TELEGRAM_TEMP: {
            method: 'POST',
            url: 'https://api.telegram.org/bot[BOT_API_KEY]/sendMessage',
            payload: '{ "chat_id": "123456789", "text": "<*IP*> on <*SLAVE*> has <*LATENCY*>ms and <*LOSS*> loss" }',
            contentType: 'application/json',
            helper: 'https://core.telegram.org/bots/api',
        },
        PAGERDUTY_TEMP: {
            method: 'POST',
            url: 'https://events.pagerduty.com/v2/enqueue',
            payload: '{\n' +
                '  "payload": {\n' +
                '    "summary": "Cocopacket alert",\n' +
                '    "source": "Your instance CocoPacket url",\n' +
                '    "severity": "warning",\n' +
                '    "custom_details": {\n' +
                '      "ping time": "<*LATENCY*>ms",\n' +
                '      "ping loss": <*LOSS*>\n' +
                '    }\n' +
                '  },\n' +
                '  "routing_key": "samplekeyhere",\n' +
                '  "dedup_key": "samplekeyhere",\n' +
                '  "links": [{\n' +
                '    "href": "https://instance.cocopacket.com/bla-bla-bla/<*IP*>/<*SLAVE*>",\n' +
                '    "text": "Graph for <*IP*> on <*SLAVE*> "\n' +
                '  }],\n' +
                '  "event_action": "trigger",\n' +
                '  "client": "Cocopacket Monitoring Service",\n' +
                '  "client_url": "https://cocopacket.com"\n' +
                '}',
            contentType: 'application/json',
            headers: '{Authorization: Token token=YOUR__TOKEN}',
            helper: 'https://v2.developer.pagerduty.com/docs/send-an-event-events-api-v2',
        },
        OPS_GENIE_EU_TEMP: {
            method: 'POST',
            url: 'https://api.eu.opsgenie.com/v2/alerts',
            contentType: 'application/json',
            headers: '{"Authorization":"GenieKey API_KEY"}',
            payload: '{ "message": "<*GROUP*> - IP:<*IP*> - PROBE:<*SLAVE*>   -->   LAT: <*LATENCY*> ms PL: <*LOSS*>%"}',
        },
        OPS_GENIE_TEMP: {
            method: 'POST',
            url: 'https://api.opsgenie.com/v2/alerts',
            contentType: 'application/json',
            headers: '{"Authorization":"GenieKey API_KEY"}',
            payload: '{ "message": "<*GROUP*> - IP:<*IP*> - PROBE:<*SLAVE*>   -->   LAT: <*LATENCY*> ms PL: <*LOSS*>%"}',
        },
    };
    if (key == undefined) return options;
    if (Object.keys(options).includes(key)) return options[key];
    return {};
};
export default GetAlertTemplate;
