import React from 'react';

class DetailOverviewPanel extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='right detail-panel'>
                <button className='back' onClick={() => this.props.handleDetailSwitch('detail')}>
                    {' '}
                    <i className='fa fa-arrow-left' />
                    {' '}
back to monitoring
                </button>
            </div>
        );
    }
}

export default DetailOverviewPanel;
