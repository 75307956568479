import React from 'react';
import Utils from "../../js/utils";

class SettingsLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            filtered: null,
        };
    }

    loadData = () => {
        const successCallback = data => this.setState({ data, filtered: this.filterData(data) });
        const data = Utils.formApiData('/status/log', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    filterData = (data) => {
        const keys = Object.keys(data);
        const filtered = {};
        let prevMsg = '';
        let prevKey = null;
        keys.forEach((key) => {
            const { timestamp, message } = data[key];
            const time = Utils.formatTimeToString(new Date(timestamp));
            const msg = message.substring(20);
            // -----  check for repetition logic -----

            if (prevMsg === msg) {
                filtered[prevKey].time.push(time);
            } else {
                prevKey = key;
                filtered[key] = { time: [time], msg };
            }
            prevMsg = msg;
        });
        return filtered;
    }

    parseContent = () => {
        const { filtered } = this.state;
        if (!filtered) return (<span>Loading log...</span>);
        const keys = Object.keys(filtered);
        const out = keys.map((key) => {
            const { time, msg } = filtered[key];
            const timestr = (time.length > 1) ? time[0] + ' - ' + time[time.length - 1] : time[0];
            return (
                <span className='log-entry' key={key}>
                    <span className='log-entry__time'>
                        <span className='entry__desc'>Time:</span>
                        <span className='entry__val'>{timestr}</span>
                    </span>
                    { time.length > 1 &&
                        <span className='log-entry__time'>
                            <span className='entry__desc'>Count:</span>
                            <span className='entry__val'>{time.length + 'x'}</span>
                        </span>
                    }
                    <span className='log-entry__msg'>
                        <span className='entry__desc'>Entry:</span>
                        <span className='entry__val'>{msg}</span>
                    </span>
                </span>
            );
        });
        return out;
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <div className='tab-content'>
                <span className='page-title'>
                    {this.props.name}
                </span>
                <div className='log-wrap'>
                    {this.parseContent()}
                </div>
            </div>
        );
    }
}

export default SettingsLog;
