/* global Chart:true */
import React from 'react';
import SVGInline from 'react-svg-inline';
import Utils from '../../js/utils.js';
import iconChevronDown from '../../../less/libs/icons/chevron_down.svg';

class Matrix extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.loadData = this.loadData.bind(this);
        this.getHead = this.getHead.bind(this);
        this.getBody = this.getBody.bind(this);
        this.probeChange = this.probeChange.bind(this);
        this.getLineOverview = this.getLineOverview.bind(this);
        this.sideButtonAction = this.sideButtonAction.bind(this);
        this.state = { data: {}, isOpen: false };
    }

    loadData(ip) {
        // ----------------- API CALL GET -----------------
        const scope = this;
        const successCallback = function (data) {
            scope.setState({ data });
        };
        const data = Utils.formApiData(`/matrix/slaves/${ip}`, true, 'json', successCallback);
        Utils.performGETcall(data);
        this.setState({ data });
    }

    getMetricValue(data) {
        const colors = Chart.colorField;
        return Utils.getLossColor(data.loss);
    }

    getHead() {
        const tds = [];
        const { data } = this.state;
        if (Utils.isSet(data)) {
            let timeKeys = data[this.props.activeProbe];
            if (Utils.isNotSet(timeKeys)) {
                return (
                    <tr>
                        <td colSpan={24} className='no-data-td'>
                            no data for
                            {this.props.activeProbe}
                        </td>
                    </tr>
                );
            }
            timeKeys = Object.keys(timeKeys);
            tds.push(<td key='desc'>TIME</td>);
            for (let t = 0; t < timeKeys.length; t++) {
                const date = new Date(timeKeys[t] * 1000);
                let hours = date.getHours();
                hours = hours < 10 ? `0${hours}` : hours;
                tds.push(<td key={t}>{hours}</td>);
            }
            return (<tr>{tds}</tr>);
        }
    }

    getBody(absolute) {
        const { data } = this.state;
        let keys = Object.keys(data);
        if (!absolute) {
            const probe = this.props.activeProbe;
            keys = [probe];
        }
        return this.getLineOverview(keys);
    }

    probeChange(probe) {
        const count = Object.keys(this.state.data).length;
        if (!this.state.isOpen && count > 1) {
            this.setState({ isOpen: true });
        } else {
            this.props.setActiveProbe(`slave=${probe}`);
            this.setState({ isOpen: false });
        }
    }

    getLineOverview(keys) {
        const { data } = this.state;
        const arr = [];
        keys.forEach((key, index) => {
            const local = data[key];
            if (Utils.isSet(local) && Object.keys(local).length >= 24) {
                const ths = [];
                ths.push(<th key={key} className='slave-name'>{key}</th>);
                let iter = 0;
                for (const t in local) {
                    const classSVG = iter == 0 ? 'first' : iter == 23 ? 'last' : '';
                    const color = this.getMetricValue(local[t]);
                    ths.push(
                        <th key={`${key}-${t}`}>
                            <svg className={classSVG}>
                                <rect width='25px' height='10px' style={{ fill: color }} />
                            </svg>
                        </th>,
                    );
                    iter += 1;
                }
                arr.push(<tr key={key} onClick={() => this.probeChange(key)}>{ths}</tr>);
            }
        });
        return arr;
    }

    getTable(absolute) {
        const wrapClass = absolute ? ' absolute' : '';
        const chevronClass = this.state.isOpen ? 'up' : 'down';
        return (
            <div className={`table-wrap${wrapClass} bars`}>
                {absolute
                    && (
                        <div className='right-wrap'>
                            <a className='side-action-button' onClick={this.sideButtonAction}>
                                <SVGInline svg={iconChevronDown} className={`chevron ${chevronClass}`} />
                            </a>
                        </div>
                    )
                }
                <table cellSpacing='0' cellPadding='0'>
                    <thead>
                        {this.getHead()}
                    </thead>
                    <tbody>
                        {this.getBody(absolute)}
                    </tbody>
                </table>
            </div>
        );
    }

    sideButtonAction() {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.ip != nextProps.ip) {
            this.loadData(nextProps.ip);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state != nextState || this.props.activeProbe != nextProps.activeProbe;
    }

    componentDidMount() {
        this.loadData(this.props.ip);
    }

    render() {
        const chevronClass = this.state.isOpen ? 'up' : 'down';
        let htmlContent = [];
        if (Utils.isSet(this.state.data)) {
            htmlContent = (
                <div className='matrix-center'>
                    {this.getTable(false)}
                    {this.state.isOpen && this.getTable(true)}
                    <div className='control-head'>
                        {Object.keys(this.state.data).length > 1
                        && (
                            <a className='side-action-button' onClick={this.sideButtonAction}>
                                <SVGInline svg={iconChevronDown} className={`chevron ${chevronClass}`} />
                            </a>
                        )
                        }
                    </div>
                </div>
            );
        }
        return (
            <div className='matrix type-bars'>
                {htmlContent}
            </div>
        );
    }
}

export default Matrix;
