/* global $:true, Chart:true */
import './chart';
import Utils from './utils.js';

const DataBasic = window.DataBasic = {

    colorField: Chart.colorField,

    loadData(ip, now, epoch, count, probe, callback) {
        const countedEpoch = Math.ceil(epoch/count)*count;
        const from = now - countedEpoch;
        count = count == undefined || Number.isNaN(count) || count <= 1 ? 1 : count;
        const url = `/agr/${count}/${ip}/${from}/${now}?${probe}`;
        // var url = '/agr/'+ count + '/' + ip + '/' + from + '/' + now + '?' + probe + '&interpolate=true';
        // ----------------- API CALL GET -----------------
        const successCallback = (dataO) => {
            const dataSeries = Utils.getPropertyOfObject(['data'], dataO, {});
            const dataLength = Object.keys(dataSeries).length;
            if (dataLength < 2) {
                const dataPrompt = (dataLength === 1) ? 'Collecting data...' : Utils.getPropertyOfObject(['error'], dataO, 'No Data');
                const chartData = { data: {}, prompt: dataPrompt };
                callback([], chartData, now, {probe, ip});
            } else {
                const stats = {
                    count: {
                        count,
                        total: dataO.info['total-count'],
                        lost: dataO.info['total-count-lost'],
                    },
                    loss: dataO.info['packet-loss'],
                    probe,
                    ip
                };
                const chartData = this.processData(dataO);
                callback(dataO.data, chartData, now, stats);
            }
        };
        const errorCallback = (response) => {
            const dataPrompt = Utils.getPropertyOfObject(['error'], response, 'No Data');
            const chartData = { data: {}, prompt: dataPrompt };
            callback([], chartData, now, {probe, ip});
        };
        const data = Utils.formApiData(url, true, 'json', successCallback, errorCallback);
        Utils.performGETcall(data);
    },

    findYaxisMax(data) {
        let topMed = 0;
        for (const key in data.data) {
            const info = data.data[key];
            topMed = info.med > topMed ? info.med : topMed;
        }
        return topMed * 1.3; // 30% additional
    },

    processData(data) {
        if (!Utils.isSet(data.data)) {
            return data;
        }
        const limit = this.findYaxisMax(data);
        const arrData = [];
        let allPackets = 0;
        const stats = {
            0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [],
        };
        /*
         * PL color is constructed :
         * Math.ceil(loss/10)
         * PL_GROUP -> PL_VALUE_RANGE
         * 0% -> 0% PL
         * 10% -> 1% - 10% PL
         * 20% -> 11% - 20% PL
         * 30% -> 21% - 30% PL
         * 40% -> 31% - 40% PL
         * 50% -> 41% - 50% PL
         * 60%+ -> 51% - 100% PL
         * */
        for (const key in data.data) {
            const info = data.data[key];
            let colorIndex = info.lost / 10;
            colorIndex = Math.ceil(colorIndex);
            colorIndex = colorIndex >= this.colorField.length ? this.colorField.length - 1 : colorIndex;
            let latency = Number(info.med); // using MEDIAN !
            latency = latency < 0 ? 0 : latency;
            allPackets += info.count;
            const timeStamp = Number(key * 1000);
            const point = {
                x: timeStamp,
                y: latency,
                segmentColor: this.colorField[colorIndex],
            };
            stats[colorIndex].push(timeStamp);
            arrData.push(point);
        }
        return {
            yaxis: limit,
            data: arrData,
            packets: {
                total_loss: data.info['total-count-lost'],
                total: data.info['total-count'],
                avg_loss: data.info['packet-loss'].avg,
                max_loss: data.info['packet-loss'].max,
            },
            latency: {
                avg: data.info['latency-info'].avg,
                min: data.info['latency-info'].min,
                max: data.info['latency-info'].max,
            },
            stats,
        };
    },

};
