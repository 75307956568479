/* eslint-disable */
import React from 'react';
import Clipboard from 'clipboard';
import moment from 'moment';
import hasher from 'hasher';
import Utils from '../../js/utils';
import GraphContainer from '../graph/graphContainer.jsx';
import Loader from '../graph/loader.jsx';

class DashboardSection extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.getEntrys = this.getEntrys.bind(this);
        this.showGraph = this.showGraph.bind(this);
        this.getDisplayData = this.getDisplayData.bind(this);
        this.filterData = this.filterData.bind(this);
        this.getRecords = this.getRecords.bind(this);
        this.sortDataByAttr = this.sortDataByAttr.bind(this);
        this.changeSortAttr = this.changeSortAttr.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        const entrysData = this.getEntrys(this.props.data);
        return {
            data: this.props.data,
            displayData: entrysData.data,
            slaveList: entrysData.slaveList,
            graphId: NaN,
            sortOption: 'description',
            sortIsAscend: true,
        };
    }

    /**
     * Takes the dashboard data and translate them into graphical entries. Also the recognition and divison of
     * an incident type (Latency, Packet loss) takes place here. Makes it faster for further filtering.
     *
     * Each data entry output contains incident info and xml output
     *
     * @param data [array] from ajax call
     * @returns {{data: {LAT: Array, PL: Array}, slaveList: Array}}
     */
    getEntrys(data) {
        const displayData = { LAT: [], PL: [] };
        const listSlaves = [];
        if (Utils.isSet(data)) {
            let pushIndex = 0;
            for (const index in data) {
                const set = data[index];
                let { ip, slave } = Utils.getIPandSlave(set.id);
                slave = slave == '' || slave == undefined ? 'LOCAL' : slave.toUpperCase();
                if (!listSlaves.includes(slave)) {
                    listSlaves.push(slave);
                }
                const interval = Utils.getTimeFormatedInterval(set);
                let category = this.props.config.ping.ips[ip].cat;
                category = category === undefined ? 'IPS' : Array.isArray(category) ? category[0] : category;
                category = category.toUpperCase();
                var incidentPacket; var incidentLatency; var
                    key;
                incidentPacket = this.createPacketEntry(set);
                incidentLatency = this.createLatencyEntry(set);
                const epoch = set.to - set.from;
                let url = `detail?ip=${ip}&`;
                url += `end=${set.to - 3600}&`;
                url += `epoch=${epoch + 3600}&`;
                url += `probe=${slave}&graphType=advanced`;
                key = set.incLost > 0 ? 'PL' : 'LAT';
                const entry = (
                    <tr key={index} data-id={index} onClick={this.showGraph}>
                        <td className='time-range'>{interval}</td>
                        {incidentLatency[0]}
                        {incidentLatency[1]}
                        {incidentPacket[0]}
                        {incidentPacket[1]}
                        <td className='link'>
                            <a href={`/#/${url}`} target='_blank' data-url={url} className='fa fa-external-link' />
                        </td>
                    </tr>
                );

                displayData[key].push({
                    id: pushIndex,
                    slave,
                    epoch,
                    ip,
                    entry,
                    category,
                    from: set.from,
                    to: set.to,
                });
                pushIndex++;
            }
        }
        return { data: displayData, slaveList: listSlaves };
    }

    /**
     * Sets the id of a graph that should be displayed. Note that only one graph at a time can be visible.
     *
     * @param e [object] event
     */
    showGraph(e) {
        let id = Number($(e.target).parent('tr').attr('data-id'));
        id = id == this.state.graphId ? NaN : id;
        this.setState({
            graphId: id,
        });
    }

    /**
     * Called from getEntrys function. Provides special formatting for packet loss entry.
     *
     * @param set [object] - incident object
     * @returns {Array}
     */
    createPacketEntry(set) {
        const output = []; // incident,value
        const style = { width: `${set.incLost}%` };
        output[0] = (
            <td className='incident incident-pl'>
                <span>PL: </span>
                <div className='bar'>
                    <div className='bar-blue' style={style} />
                </div>
                <span className='text-percentage' />
            </td>
        );
        output[1] = (
            <td className='incident-value'>
                <span>{`${set.avgLost} , ${set.incLost} %`}</span>
            </td>
        );
        return output;
    }

    /**
     * Called from getEntrys function. Provides special formatting for latency loss entry.
     *
     * @param set [object] - incident object
     * @returns {Array}
     */
    createLatencyEntry(set) {
        const output = []; // incident,value
        let val = ((set.incLat / set.avgLat) - 1) * 100;
        let barColor = 'bar-violet';
        if (val > 100) {
            barColor = 'bar-red';
            val = 100;
        }
        const style = { width: `${val}%` };
        output[0] = (
            <td className='incident'>
                <span>LAT: </span>
                <div className='bar'>
                    <div className={barColor} style={style} />
                </div>
            </td>
        );
        output[1] = (
            <td className='incident-value'>
                <span>{`${set.avgLat} , ${set.incLat} ms`}</span>
            </td>
        );
        return output;
    }

    /**
     * Called directly from render method. Provides all the necessary calls to get valid data to display.
     * Filters data accordingly and returns data in displayable format xml.
     *
     * @returns {*} [xml object]
     */
    getDisplayData() {
        const data = this.state.displayData;
        let displayData;
        if (Utils.isSet(data)) {
            displayData = this.filterData(data);
        }
        if (displayData) {
            return displayData;
        }
        return (
            <tr>
                <td className='server-name'> no data </td>
            </tr>
        );
    }

    /**
     * Filters data, function passes latency and packet loss entries based on filters to 'getRecords' function, for
     * further filtering.
     *
     * @param data [object] incident entries
     * @returns {*}
     */
    filterData(data) {
        const entries = [];
        if (this.props.sortAttributes.incidentType == 'ALL') {
            this.getRecords(entries, [...data.LAT, ...data.PL]);
        } else {
            data = data[this.props.sortAttributes.incidentType];
            this.getRecords(entries, data);
        }
        return Utils.isSet(entries) ? entries : false;
    }

    /**
     * Provides further filtering by slave
     *
     * @param entries [array] empty array reference to collect output values
     * @param data [array] incident values already filtered by incident type (latency, packet loss, all)
     */
    getRecords(entries, data) {
        let setGraph = true;
        data = this.sortDataByAttr(data);
        for (const index in data) {
            const local = data[index];
            const info = this.props.config.ping.ips[local.ip];
            if (info != undefined) {
                if (this.props.sortAttributes.slave == 'ALL' || this.props.sortAttributes.slave == local.slave) {
                    entries.push(local.entry);
                    if (local.id == this.state.graphId && setGraph) {
                        const probe = local.slave;
                        const graphConf = {
                            endPoint: this.props.sortAttributes.endpoint,
                            epoch: this.props.sortAttributes.epoch,
                            probe: `slave=${probe}`,
                            graphType: 'advanced',
                        };
                        entries.push(
                            <tr key={`${index}graph`}>
                                <td colSpan='7' className='graph'>
                                    <GraphContainer
                                        hideGraphInfo
                                        selectedIP={local.ip}
                                        graphConfig={graphConf}
                                        incidentHighlight={{ from: local.from, to: local.to }}
                                    />
                                </td>
                            </tr>,
                        );
                        setGraph = false;
                    }
                }
            }
        }
    }

    /**
     * Sort function. Reacts to header sort inputs.
     *
     * @param data [array]
     * @returns {*}
     */
    sortDataByAttr(data) {
        const option = this.state.sortOption;
        switch (option) {
        case 'category':
            if (this.state.sortIsAscend) {
                return data.sort((a, b) => -(a.category < b.category) || +(a.category != b.category));
            }
            return data.sort((a, b) => -(a.category > b.category) || +(a.category != b.category));

        case 'slave':
            if (this.state.sortIsAscend) {
                return data.sort((a, b) => -(a.slave < b.slave) || +(a.slave != b.slave));
            }
            return data.sort((a, b) => -(a.slave > b.slave) || +(a.slave != b.slave));

        default:
            return data;
        }
    }

    /**
     * Provides functionality to change sort property.
     *
     * @param e [event]
     */
    changeSortAttr(e) {
        const val = e.target.getAttribute('data-name');
        if (val != this.state.sortOption) {
            this.setState({
                sortOption: val,
            });
        } else {
            this.setState({
                sortIsAscend: !this.state.sortIsAscend,
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState != this.state;
    }

    componentDidUpdate() {
        const clipboard = new Clipboard('.ip-copy-btn');
    }

    render() {
        return (
            <div className='dashboard-section under-section'>
                <div className='dashboard-table'>
                    <table>
                        <thead>
                            <tr>
                                <th onClick={this.changeSortAttr} data-name='fromto'>From - To</th>
                                <th onClick={this.changeSortAttr} data-name='incident'>Latency change (%)</th>
                                <th onClick={this.changeSortAttr} data-name='change'>Avg, Incident value</th>
                                <th onClick={this.changeSortAttr} data-name='incident'>Packet Loss change (%)</th>
                                <th onClick={this.changeSortAttr} data-name='change'>Avg, Incident value</th>
                                <th>Show Graph</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.getDisplayData() }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default DashboardSection;
