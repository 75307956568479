import React from 'react';
import update from 'immutability-helper';
import Utils from '../../js/utils';

/** *
 * PROPS
 * probesList [obj] -{'AMS': false, 'LON': true, ...} - bool means that probe is selected for given ip
 * title [optional] [string] - ['probes', 'report']
 * allowed [optional] [array]- e.g.['AMS', 'LON', ...] - all probe_names that are available for selection
 * setSelectedProbes [func] - set probes in parent component's state
 * inputIP - [string] - '127.0.0.0.1'
 */

class ProbeSelection extends React.Component {
    constructor(props) {
        super(props);
        this.printProbes = this.printProbes.bind(this);
        this.getProbesList = this.getProbesList.bind(this);
        this.clickInput = this.clickInput.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.setAllProbes = this.setAllProbes.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.state = this.getInitialState();
    }

    getInitialState() {
        this.probeKeys = Utils.sortKeys(this.props.probesList);
        return {
            selectAll: false,
            lastSelection: { id: -1, bool: false },
            width: 0,
            ASIA: false,
            CACHE: false,
            EU: false,
            NA: false,
            SA: false,
        };
    }

    printProbes() {
        if (Utils.isNotSet(this.props.probesList)) {
            return [];
        }
        const probesList = this.getProbesList();
        const ipVersion4 = Utils.isNotSet(ipVersion4) ||  (Utils.isSet(ipVersion4) && ipVersion4);
        if (!probesList) {
            return (
                <div className='loc-monitoring-wrap'>
                    <span className='no-locations'>
                        {'No available monitoring locations for IP ' + (ipVersion4 ? 'v4' : 'v6')}
                    </span>
                </div>
            );
        }
        const title = Utils.isSet(this.props.title) ? this.props.title : 'Locations for Monitoring';
        return (
            <div className='loc-monitoring-wrap'>
                <span className='label-title upperCase'>
                    {title}
                    <i className='fa fa-star' />
                    <span className='label-title__small '>
                        {'(use '}
                        <strong>shift</strong>
                        {' to multi select&deselect)'}
                    </span>
                </span>
                {this.getProbesList()}
            </div>
        );
    }

    getProbesList() {
        const { probeKeys } = this;
        const { ipVersion4, probesList } = this.props;
        const ipV4 = Utils.isNotSet(ipVersion4) ||  (Utils.isSet(ipVersion4) && ipVersion4);
        const data = probesList;
        let probes = [];
        const sourceKey = ipV4 ? 'source' : 'source6';
        if (Utils.isNotSet(data)) { return probes; }
        const filtered = probeKeys;
        // Turn of filtering until ANTON fixes probes updated @TODO
        // const filtered = probeKeys.filter((key) => {
        //     const sourceVal = Utils.getPropertyOfObject(['slaveSources', key, sourceKey], this.props, '');
        //     return Utils.isSet(sourceVal);
        // });
        filtered.forEach((probe, index) => {
            const isChecked = data[probe];
            let isDisabled = false;
            if (this.props.tab === 'report') {
                isDisabled = Utils.isSet(this.props.allowed) ? !this.props.allowed.includes(probe) : true;
            }
            probes.push(
                <li
                    className='slave'
                    key={index}
                    onClick={(e) => {
                        if (!isDisabled) {
                            this.clickInput(e, index, probe);
                        }
                    }}
                >
                    <label className='inp-check inp-check-no-event'>
                        <input
                            type='checkbox'
                            name='loc-monitoring'
                            id={probe}
                            checked={isChecked}
                            onChange={() => {}}
                            disabled={isDisabled}
                        />
                        <span className='probeName'>{probe}</span>
                    </label>
                </li>,
            );
        });
        const count = Object.keys(data).length + 1;
        const classProbe = count < 10 ? 'row' : 'column';
        const probePerWidth = Math.round(this.state.width / 300);
        let height = (count / probePerWidth) * 35;
        height = height < 35 ? 35 : height;
        const style = { height: `${height}px` };
        const smartGroupingForTrip = window.location.hostname === 'trip.cocopacket.com';
        if (probes.length === 0) { return null; }
        return (
            <ul className={`loc-monitoring-list ${classProbe}`} style={style}>
                <li className='slave' onClick={this.selectAll}>
                    <label className='inp-check inp-check-no-event'>
                        <input
                            type='checkbox'
                            name='loc-monitoring'
                            id='Select all'
                            checked={this.state.selectAll}
                            onChange={() => {}}
                        />
                        <span>* ALL *</span>
                    </label>
                </li>
                {smartGroupingForTrip && this.getContinentSelection()}
                {probes}
            </ul>
        );
    }

    getContinentSelection = () => {
        const selectAllEU = () => {
            const locations = ['AMS', 'ATH', 'BRA', 'BRU', 'BUC', 'CPH', 'DUB', 'FRA', 'KYI', 'LIS', 'LON', 'MAD', 'MIL', 'MRS', 'PAR', 'PRG', 'SOF', 'STO', 'VIE', 'WAR', 'ZAG', 'ZUR'];
            const newState = selectALLinContinent(!this.state.EU, locations);
            this.props.setSelectedProbes(newState);
            this.setState(prev => ({ EU: !prev.EU }));
        };
        const selectAllNA = () => {
            const locations = ['ASH', 'ATL', 'BOS', 'CHI', 'DAL', 'DEN', 'HOU', 'LAX', 'MCA', 'MEX', 'MIA', 'NYC', 'QRO', 'RES', 'SEA', 'SJC', 'TOR'];
            const newState = selectALLinContinent(!this.state.NA, locations);
            this.props.setSelectedProbes(newState);
            this.setState(prev => ({ NA: !prev.NA }));
        };
        const selectAllSA = () => {
            const locations = ['SAO', 'BOG', 'FRT', 'SCL'];
            const newState = selectALLinContinent(!this.state.SA, locations);
            this.props.setSelectedProbes(newState);
            this.setState(prev => ({ SA: !prev.SA }));
        };
        const selectAllAsia = () => {
            const locations = ['IST', 'JAK', 'HKG', 'SGP', 'TYO', 'TLV'];
            const newState = selectALLinContinent(!this.state.ASIA, locations);
            this.props.setSelectedProbes(newState);
            this.setState(prev => ({ ASIA: !prev.ASIA }));
        };
        const selectAllCache = () => {
            const locations = ['CACHE'];
            const newState = selectALLinContinent(!this.state.CACHE, locations);
            this.props.setSelectedProbes(newState);
            this.setState(prev => ({ CACHE: !prev.CACHE }));
        };
        const selectALLinContinent = (bool, locations) => {
            const list = this.props.probesList;
            const data = Object.keys(list);
            const options = locations.join('-') + '-';
            const filtered = data.filter((key) => {
                const pre = key.split('-')[0];
                return options.includes(pre + '-');
            });
            const newstate = {};
            filtered.forEach((probe_name) => {
                // allow 'all' action to only mark allowed inputs for report section
                let val = bool;
                if (bool && this.props.tab === 'report') {
                    val = Utils.isSet(this.props.allowed) && this.props.allowed.includes(probe_name);
                }
                newstate[probe_name] = val;
            });
            return { ...list, ...newstate };
        };
        return (
            <>
                <li className='slave' onClick={selectAllAsia}>
                    <label className='inp-check inp-check-no-event'>
                        <input
                            type='checkbox'
                            name='loc-monitoring'
                            checked={this.state.ASIA}
                            onChange={() => {}}
                        />
                        <span>* ASIA *</span>
                    </label>
                </li>
                <li className='slave' onClick={selectAllEU}>
                    <label className='inp-check inp-check-no-event'>
                        <input
                            type='checkbox'
                            name='loc-monitoring'
                            checked={this.state.EU}
                            onChange={() => {}}
                        />
                        <span>* EU *</span>
                    </label>
                </li>
                <li className='slave' onClick={selectAllNA}>
                    <label className='inp-check inp-check-no-event'>
                        <input
                            type='checkbox'
                            name='loc-monitoring'
                            checked={this.state.NA}
                            onChange={() => {}}
                        />
                        <span>* NA *</span>
                    </label>
                </li>
                <li className='slave' onClick={selectAllSA}>
                    <label className='inp-check inp-check-no-event'>
                        <input
                            type='checkbox'
                            name='loc-monitoring'
                            checked={this.state.SA}
                            onChange={() => {}}
                        />
                        <span>* SA *</span>
                    </label>
                </li>
                <li className='slave' onClick={selectAllCache}>
                    <label className='inp-check inp-check-no-event'>
                        <input
                            type='checkbox'
                            name='loc-monitoring'
                            checked={this.state.CACHE}
                            onChange={() => {
                            }}
                        />
                        <span>* CACHE *</span>
                    </label>
                </li>
            </>
        );
    }

    clickInput(e, id, probe) {
        const probes = this.props.probesList;
        const last = this.state.lastSelection;
        let selectionForProps = {};
        if (last.id >= 0 && e.shiftKey) {
            const keys = this.probeKeys;
            let from = last.id;
            let to = id;
            if (from > to) {
                const temp = from;
                from = to;
                to = temp;
            }
            selectionForProps = { ...this.props.probesList };
            for (let i = from; i <= to; i++) {
                const key = keys[i];
                selectionForProps[key] = last.bool;
            }
            this.setState({ lastSelection: { id: -1, bool: false } });
        } else {
            const bool = probes[probe];
            selectionForProps = { ...this.props.probesList, [probe]: !bool };
            this.setState({ lastSelection: { id, bool: !bool } });
        }
        this.props.setSelectedProbes(selectionForProps);
    }

    selectAll() {
        this.setState((prevState) => {
            const bool = !prevState.selectAll;
            this.props.setSelectedProbes(this.setAllProbes(bool));
            return {
                selectAll: bool, NA: bool, SA:bool, EU: bool, ASIA: bool,
            };
        });
    }

    setAllProbes(bool) {
        const data = Object.keys(this.props.probesList);
        const newstate = {};
        data.forEach((probe_name) => {
            // allow 'all' action to only mark allowed inputs for report section
            let val = bool;
            if (bool && this.props.tab === 'report') {
                val = Utils.isSet(this.props.allowed) && this.props.allowed.includes(probe_name);
            }
            newstate[probe_name] = val;
        });
        return newstate;
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.tab != nextProps.tab) {
            this.setState({
                selectAll: false,
                lastSelection: { id: -1, bool: false },
                EU: false,
                ASIA: false,
                NA: false,
                SA: false,
            });
        }
        return this.state != nextState
               || this.props.inputIP != nextProps.inputIP
               || this.props.tab != nextProps.tab
               || this.props.probesList != nextProps.probesList;
    }

    updateDimensions() {
        this.setState({ width: Utils.getModalWidth() - 150 });
    }

    render() {
        return (
            <div className='set-probes-action'>
                {this.printProbes()}
            </div>
        );
    }
}

export default ProbeSelection;
