import React from 'react';
import { DragSource } from 'react-dnd';
import Utils from '../../../js/utils.js';

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
    };
}

const ipSource = {
    beginDrag(props) {
        return {
            name: props.title,
        };
    },

    endDrag(props, monitor) {
        const item = monitor.getItem();
        const dropResult = monitor.getDropResult();

        if (dropResult) {
            props.addValToQuickList({
                isIp: true, ip: props.ip, title: props.title, data: props,
            });
        }
    },
};

/**
 * PROPS
 *
 * color -
 * title - [string] - ip's title
 * updateSelectedIP - [func] - updates selected ip
 * ip - [string] - IP address
 * slave - [string] - selected probe
 */

class IP extends React.PureComponent {
    colorHidden = () => {
        const { color } = this.props;
        if (color == '' || color == undefined) {
            return 'hidden';
        }
        return '';
    }

    getStyle = () => ({ fill: this.props.color });

    handleClick = (e) => {
        e.preventDefault();
        $(window).scrollTop(0);
        this.props.updateSelectedIp(this.props.ip, this.props.slave);
    }

    getTitle = () => {
        let { title } = this.props;
        if (!Utils.isSet(this.props.title)) {
            title = this.props.ip;
        }
        if (Utils.isSet(this.props.slave) && this.props.slave.length > 0) {
            title += `  @  ${this.props.slave}`;
        }
        return title;
    }

    render() {
        const { connectDragSource } = this.props;
        const { isDragging } = this.props;
        const dragStyle = {
            opacity: isDragging ? 0.5 : 1,
            cursor: 'move',
        };
        return connectDragSource(
            <li style={dragStyle} className='ip-li'>
                <svg className={this.colorHidden()}>
                    <circle cx='5' cy='5' r='4' style={this.getStyle()} />
                </svg>
                <a href='#' className='ip-butt' value={this.props.ip} title={this.props.title} onClick={this.handleClick}>
                    {this.getTitle()}
                </a>
            </li>,
        );
    }
}

export default DragSource('ip', ipSource, collect)(IP);
