import React from 'react';
import ModalWrapper from './modalWrapper.jsx';
import Utils from '../../js/utils';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        newName: this.props.name,
    })

    openModal = () => this.setState(this.getInitialState());


    successRedirect = () => {
        this.props.loadSlaveList();
        this.setState(this.getInitialState());
    }

    /**
     * generic callbacks in a form suited for modal-wrapper
     * @param modalCallback - [ref] - reference to a modal-wrapper function that handles api callbacks
     */
    getGenericCallback = (modalCallback) => {
        const callbacks = {};
        callbacks.success = succ => modalCallback({ verdict: true });
        callbacks.error = err => modalCallback({ verdict: false, errorPrompt: err.error });
        return callbacks;
    }

    saveChanges = (e, modalCallback) => {
        const callbacks = this.getGenericCallback(modalCallback);
        const data = Utils.formApiData('/slaves', true, 'json', callbacks.success, callbacks.error);
        let { newName } = this.state;
        const oldName = this.props.name;
        newName = newName.replace(/\ /g, '_');
        data.contentType = 'application/json';
        data.data = { oldName, newName };
        Utils.performPUTcall(data, 'PUT');
    }

    render() {
        const Trigger = ({ action }) => (
            <a onClick={action} className='trigger'>
                <i className='fa fa-cog' />
            </a>
        );
        const styles = { content: { height: 'auto', width: '80%' } };
        const renderComponent = confirmCallback => (
            <form onSubmit={e => this.saveChanges(e, confirmCallback)}>
                <h2>
Probe's Settings for
                    <strong>{this.props.name}</strong>
                </h2>
                <span className='alias-box'>
                    <label className='label-title'>
                        <span>Change name:</span>
                    </label>
                    <input
                        type='text'
                        className='input-alias'
                        placeholder='new_name'
                        value={this.state.newName}
                        onChange={e => this.setState({ newName: e.target.value })}
                    />
                </span>
                <div className='butts'>
                    <button
                        className='modal-confirm'
                        type='submit'
                        disabled={this.state.newName.length <= 0 || this.state.newName === this.props.name}
                    >
                        Save
                    </button>
                </div>
            </form>
        );
        return (
            <ModalWrapper
                Trigger={Trigger}
                style={styles}
                openModal={this.openModal}
                redirect={this.successRedirect}
                render={renderComponent}
                customWrapClass='modalProbeSettings'
            />
        );
    }
}

export default App;
