import React from 'react';

const SearchTips = () => (
    <div className='search-tips'>
        <h2> HOW TO SEARCH </h2>
        <div className='content'>
            <span className='title'>IP</span>
            <p>
                <strong>[ ip ]</strong>
                (example : '1.1.1.1')
            </p>

            <span className='title'>TEXT</span>
            <p>
                <strong>[ text ]</strong>
                (example : 'work')
                <br />
                <strong>[ text | slave_name ]</strong>
                (example : 'work | AMS')
                <br />
            </p>

            <span className='title'>AS</span>
            <p>
                <strong>[ as'number' ]</strong>
                (example: 'as6')
                <br />
                <strong>[ as'number' | slave_name ]</strong>
                (example: 'as6 | AMS')
                <br />
                <strong>[ as'number' | slave_name_1,slave_name_2 ]</strong>
                (example: 'as6 | AMS,ATL,NYC')
                <br />
            </p>


            <span className='title'>SUBNET</span>
            <p>
                <strong>[ ip/subnet ]</strong>
                (example: '1.1.1.1/24')
                <br />
            </p>
            <span className='title'>MOVE TO QUICKLIST</span>
            <p>
                Button moves search result in the sidepanel's quicklist. Max move limit is 150 IPs.
            </p>
        </div>
    </div>
);

export default SearchTips;
