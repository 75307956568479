/* global $:true */
import React from 'react';
import Clipboard from 'clipboard';
import Utils from '../../../../js/utils.js';
import ShareUrlModal from '../../../modals/shareUrl.modal.jsx';

/*
* ipDetails [object] -
* ipName [string] - alias
* as [string] - autonomous system id
* setGraphType [func] -
* */

class InnerGraphHeaderMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            graphType: this.props.ipDetails.graphType,
            accuracy: this.props.ipDetails.accuracy,
        };
    }

    changeGraphType = (type) => {
        this.setState((prev) => {
            const graphType = Utils.isNotSet(type) ? prev.graphType === 'basic' ? 'advanced' : 'basic' : type;
            this.props.setGraphType(graphType);
            return { graphType };
        });
    }

    getSwitches = () => {
        const isSmoke = this.state.graphType === 'advanced';
        return (
            <div className='switches'>
                <div className='switch'>
                    <span className={isSmoke ? '' : 'active'} onClick={() => this.changeGraphType('basic')}>
                        Basic
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={() => this.changeGraphType()} checked={isSmoke} />
                        <span className='slider round' />
                    </label>
                    <span className={isSmoke ? 'active' : ''} onClick={() => this.changeGraphType('advanced')}>
                        Smoke
                    </span>
                </div>
                <div className='switch'>
                    <ShareUrlModal triggeredFromMobile {...this.props} graphType={this.state.graphType} />
                </div>
            </div>
        );
    }

    getTitle = () => (
        <div className='title-wrap'>
            <span className='title'>
                <a onClick={this.copyIPAction}>
                    <span ref='ip-title' data-clipboard-text={this.props.ipName} className='ip ip-info copy-btn'>
                        {this.props.ipName}
                    </span>
                </a>
            </span>
            <span className='title'>
                <a onClick={this.copyASAction}>
                    <span ref='as-title' className='as-info copy-btn' data-clipboard-text={this.props.as}>
                            AS:
                        {' '}
                        <span className='val'>{ this.props.as }</span>
                    </span>
                </a>
            </span>
        </div>
    )

    copyIPAction = (e) => {
        const scope = this;
        const selector = $(this.refs['ip-title']);
        const color = selector.css('color');
        selector.css('color', '#ADC852');
        selector.text('COPIED');
        setTimeout(() => {
            selector.text(scope.props.ipName);
            selector.css('color', color);
        }, 2000);
    }

    copyASAction = (e) => {
        const scope = this;
        const selector = $(this.refs['as-title']).find('.val');
        const color = selector.css('color');
        selector.css('color', '#ADC852');
        selector.text('COPIED');
        setTimeout(() => {
            selector.text(scope.props.as);
            selector.css('color', color);
        }, 2000);
    }

    componentDidMount() {
        const clipboard = new Clipboard('.copy-btn');
    }

    render() {
        return (
            <div className='panel'>
                {this.getTitle()}
                {this.getSwitches()}
            </div>
        );
    }
}

export default InnerGraphHeaderMobile;
