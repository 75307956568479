/* global $:true */
import React from 'react';
import Utils from '../../js/utils.js';
import GraphContainer from '../graph/graphContainer.jsx';
import EpochPicker from '../graph/header/epochPicker.jsx';
import GraphProbe from '../graph/header/probes.jsx';
import TopPanel from '../topPanel/topPanel.jsx';

class MainPanelDetailProbes extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.getProbesGraphs = this.getProbesGraphs.bind(this);
        this.getIpGraphs = this.getIpGraphs.bind(this);
        this.getGeneratedContent = this.getGeneratedContent.bind(this);
        this.setEpochWithEndpoint = this.setEpochWithEndpoint.bind(this);
        this.setEpochFromZoom = this.setEpochFromZoom.bind(this);

        this.getEpoch = this.getEpoch.bind(this);
        this.getProbes = this.getProbes.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.getGraphSwitch = this.getGraphSwitch.bind(this);
        this.spanGraphTypeClick = this.spanGraphTypeClick.bind(this);
        this.onChangeHandlerGraphType = this.onChangeHandlerGraphType.bind(this);
        this.changeGraphType = this.changeGraphType.bind(this);
        this.setActiveProbe = this.setActiveProbe.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        const state = {
            graphType: 'basic',
            graphEpoch: 86400,
            graphEndpoint: Utils.getTimeNow(),
            customEpoch: false,
        };
        if (this.props.type == 'ip') {
            state.selectedProbe = `slave=${Object.keys(this.props.slaveList)[0]}`;
        }
        return state;
    }

    getProbesGraphs() {
        const data = this.props.probesList;
        const graphs = [];
        let graphConfig;
        for (const graph in data) {
            const probe = `slave=${data[graph]}`;
            graphConfig = {
                epoch: this.state.graphEpoch,
                probe,
                endPoint: this.state.graphEndpoint,
                graphType: this.state.graphType,
                accuracy: 'normal',
            };
            graphs.push(
                <div className='probe-graph-wrap multiple' key={probe}>
                    <GraphContainer
                        {...this.props}
                        showName
                        ipName={data[graph]}
                        ipDetails={graphConfig}
                        hideGraphInfo
                        showSetIpModal={false}
                        setEpochFromZoom={this.setEpochFromZoom}
                    />
                </div>,
            );
        }
        return graphs;
    }

    getIpGraphs() {
        const graphs = [];
        let graphConfig;
        let data = this.props.list;
        if (data.length == 1 && !data[0].isIp) {
            data = data[0].data.data.I_P_S;
        }
        for (const i in data) {
            const ip = data[i];
            graphConfig = {
                ip: ip.ip,
                epoch: this.state.graphEpoch,
                probe: this.state.selectedProbe,
                endPoint: this.state.graphEndpoint,
                graphType: this.state.graphType,
                accuracy: 'normal',
                title: ip.title,
            };
            const local = graphConfig;
            graphs.push(
                <div className='probe-graph-wrap multiple' key={local.ip}>
                    <GraphContainer
                        {...this.props}
                        ipName={`${local.ip} ${local.title}`}
                        showName
                        ipDetails={local}
                        hideGraphInfo
                        showSetIpModal={false}
                        selectedIP={local.ip}
                        showTraceLegend
                        setEpochFromZoom={this.setEpochFromZoom}
                    />
                </div>,
            );
        }
        return graphs;
    }

    getGeneratedContent() {
        if (this.props.type == 'probe') {
            return this.getProbesGraphs();
        }
        return this.getIpGraphs();
    }

    setEpochWithEndpoint(epoch, endpoint) {
        this.setState({
            graphEpoch: epoch,
            graphEndpoint: endpoint,
        });
    }

    setEpochFromZoom(epoch, to, res) {
        this.setState({
            graphEpoch: epoch,
            graphEndpoint: to,
        });
    }


    getEpoch() {
        return this.state.graphEpoch;
    }

    copyEffect(e) {
        const sel = $(e.target);
        Utils.copyEffect(sel);
    }

    getProbes() {
        const selection = [];
        const lis = [];
        const conf = this.props.ping.ips;
        for (const ip in this.props.list) {
            const { slaves } = conf[ip];
            for (const probe in slaves) {
                if (!selection.contains(probe)) {
                    selection.push(probe);
                    lis.push(
                        <li
                            key={probe}
                            className='values'
                            data-tab={slaves[probe]}
                            value={probe}
                            onClick={this.probeChange}
                        >
                            {probe}
                        </li>,
                    );
                }
            }
        }
        return (
            <div className='probe-header'>
                <ul className='probes-ul first-probe-list'>
                    {lis}
                </ul>
            </div>
        );
    }

    getTitle() {
        if (this.props.type == 'probe') {
            return (<span className='title'>{this.props.ipName}</span>);
        }
        return (
            <span className='title light'>
                {`QuickList IP selection [${this.props.list.length}]`}
            </span>
        );
    }

    getGraphSwitch() {
        const isSmoke = this.state.graphType == 'advanced';
        return (
            <div className='switches'>
                <div className='switch'>
                    <span className={isSmoke ? '' : 'active'} data-value='basic' onClick={this.spanGraphTypeClick}>
                        Basic
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={this.onChangeHandlerGraphType} checked={isSmoke} />
                        <span className='slider round' />
                    </label>
                    <span className={isSmoke ? 'active' : ''} data-value='advanced' onClick={this.spanGraphTypeClick}>
                        Smoke
                    </span>
                </div>
            </div>
        );
    }

    spanGraphTypeClick(e) {
        const type = e.target.getAttribute('data-value');
        this.changeGraphType(type);
    }

    onChangeHandlerGraphType() {
        const type = this.state.graphType == 'basic' ? 'advanced' : 'basic';
        this.changeGraphType(type);
    }

    changeGraphType(type) {
        this.setState({
            graphType: type,
        });
    }

    setActiveProbe(probe) {
        this.setState({
            selectedProbe: probe,
        });
    }

    render() {
        return (
            <div id='mother'>
                <div className='main-panel detail-ip-probes'>
                    { !this.props.isMobile
                    && (
                        <TopPanel
                            {...this.props}
                            ipDetails={this.state.ipDetails}
                            triggerSidebarAction={this.triggerSidebarAction}
                            sidePanelStatus={this.state.sidePanelStatus}
                            addValToQuickList={this.addValToQuickList}
                        />
                    )
                    }
                    <div className='header '>
                        { this.props.type == 'ip'
                        && (
                            <GraphProbe
                                activeProbe={this.state.selectedProbe}
                                setActiveProbe={this.setActiveProbe}
                                probesList={Object.keys(this.props.slaveList)}
                            />
                        )
                        }
                    </div>
                    <div className='generatedContent'>
                        <div className='panel'>
                            <div className='title-wrap'>
                                {this.getTitle()}
                            </div>
                            {this.getGraphSwitch()}
                            <EpochPicker
                                epoch={this.state.graphEpoch}
                                setEpochWithEndpoint={this.setEpochWithEndpoint}
                                setEpochFromZoom={this.setEpochFromZoom}
                            />
                        </div>
                        {this.getGeneratedContent()}
                    </div>
                </div>
            </div>
        );
    }
}

export default MainPanelDetailProbes;
