import React from 'react';
import SearchTips from './searchTips.jsx';
import Utils from '../../../js/utils';
import GraphContainer from '../../graph/graphContainer.jsx';

class SearchResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailID: NaN
        };
    }

    getTable = (displayData) => {
        const option = this.props.sortOption;
        const isAscend = this.props.sortIsAscend;
        return (
            <table>
                <thead>
                    <tr>
                        <th onClick={this.props.markAllInputs}>ALL</th>
                        <th onClick={() => this.props.changeSortAttr('ip')} className={option === 'ip' ? 'active' : ''}>
                        IP
                            <svg className={`navigation-arrow ${isAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                {/* eslint-disable-next-line max-len */}
                                <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                            </svg>
                        </th>
                        <th onClick={() => this.props.changeSortAttr('alias')} className={option === 'alias' ? 'active' : ''}>
                        ALIAS
                            <svg className={`navigation-arrow ${isAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                {/* eslint-disable-next-line max-len */}
                                <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                            </svg>
                        </th>
                        <th onClick={() => this.props.changeSortAttr('packetLoss')} className={option === 'packetLoss' ? 'active' : ''}>
                        PL
                            <svg className={`navigation-arrow ${isAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                {/* eslint-disable-next-line max-len */}
                                <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                            </svg>
                        </th>
                        <th onClick={() => this.props.changeSortAttr('slaves')} className={option === 'slaves' ? 'active' : ''}>
                        SLAVES
                            <svg className={`navigation-arrow ${isAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                {/* eslint-disable-next-line max-len */}
                                <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                            </svg>
                        </th>
                        <th onClick={() => this.props.changeSortAttr('cat')} className={option === 'cat' ? 'active' : ''}>
                        CATEGORY
                            <svg className={`navigation-arrow ${isAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                {/* eslint-disable-next-line max-len */}
                                <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                            </svg>
                        </th>
                        <th onClick={() => this.props.changeSortAttr('as')} className={option === 'as' ? 'active' : ''}>
                        AS
                            <svg className={`navigation-arrow ${isAscend ? 'ascend' : 'descend'}`} viewBox='0 0 11 7'>
                                {/* eslint-disable-next-line max-len */}
                                <path d='M5.46 7c-.18 0-.33-.06-.45-.18L.18 1.99c-.24-.24-.24-.63 0-.9.24-.24.63-.24.9 0l4.38 4.38 4.38-4.38c.24-.24.63-.24.9 0 .24.24.24.63 0 .9L5.91 6.82c-.12.12-.3.18-.45.18z' />
                            </svg>
                        </th>
                        <th>DETAIL</th>
                    </tr>
                </thead>
                <tbody>{displayData}</tbody>
            </table>
        );
    }

    getContent = (inputValOnClose) => {
        const { results, deleteHistory } = this.props;
        return (
            <>
                { results.length == 0 && <SearchTips /> }
                { results.length > 0 &&
                    <div>
                        { Utils.isSet(inputValOnClose) &&
                            <h2>
                                Last Search :
                                {inputValOnClose}
                                <a onClick={deleteHistory} className='fa fa-times' />
                            </h2>
                        }
                        {this.getTable(this.getData())}
                    </div>
                }
            </>
        );
    }

    getData = () => {
        const entrys = [];
        const list = this.props.orderData();
        let counter = 0;
        let backgClass = 'dark';
        for (let i = 0; i < list.length; i++) {
            if (counter > this.props.threshold) { return entrys; }
            const temp = list[i];
            if (Utils.isSet(temp.slaves)) {
                counter += 1; // actual insert into the search
                let { slaves } = temp;
                slaves = slaves.length > 20 ? `${slaves.substring(0, 20)}...` : slaves;
                backgClass = backgClass === 'light' ? 'dark' : 'light';
                const data = {
                    ...temp,
                    highlight: this.state.detailID == i ? 'active' : '',
                    backgClass
                };
                entrys.push(this.createEntry(i, data, slaves, list));
                if (this.state.detailID == i) {
                    slaves = temp.slaves.split(',')[0];
                    entrys.push(this.createGraphEntry(i, temp.ip, slaves));
                }
            }
        }
        return entrys;
    }

    createEntry = (index, data, slaves, list) => {
        const ipLoss = this.props.colorList[data.ip];
        const packetLoss = this.props.getIpColor(ipLoss);
        const style = { fill: packetLoss };
        const highlight = this.state.detailID == index ? 'active' : '';
        let cat = data.cat.split('->');
        cat = Utils.isNotSet(data.cat) ? '-no-category-' : (cat.length > 2 ? '...' : '') + cat[cat.length - 2];
        return (
            <tr key={index} onClick={() => this.props.changeIP(data.ip)} className={`${highlight} ${data.backgClass}`}>
                <td onClick={e => this.props.inputClick(e, index, list)} className='first'>
                    <label className='inp-check inp-check-no-event'>
                        <input
                            type='checkbox'
                            checked={this.props.inputValues[data.ip]}
                            onChange={() => {}}
                        />
                        <span />
                    </label>
                </td>
                <td className='ip'>{data.ip}</td>
                <td className='alias'>{data.alias}</td>
                <td className='pl'>
                    <svg className={this.props.colorHidden(packetLoss)}>
                        <circle cx='6' cy='6' r='6' style={style} />
                    </svg>
                </td>
                <td className='slaves'>{slaves}</td>
                <td className='cat'>{cat}</td>
                <td className='cat'>{data.as}</td>
                <td className='detail last'>
                    <a onClick={e => this.showGraph(e,index)} className='show-graph'>
                        <i className='fa fa-bar-chart' />
                    </a>
                </td>
            </tr>
        );
    }

    createGraphEntry = (index, ip, slave) => {
        const graphConf = {
            endPoint: Utils.getTimeNow(),
            epoch: 86400,
            probe: `slave=${slave}`,
            graphType: 'advanced',
            accuracy: 'normal',
            customEpoch: false,
            isFavourite: false,
        };
        return (
            <tr key={`${index}graph`}>
                <td colSpan='7' className='graph'>
                    <GraphContainer
                        hideGraphInfo
                        selectedIP={ip}
                        ipDetails={graphConf}
                        theme={this.props.theme}
                        noZoom={true}
                    />
                </td>
            </tr>
        );
    }

    showGraph = (e, id) => {
        id = id == this.state.detailID ? NaN : id;
        this.setState({ detailID: id });
        e.stopPropagation();
    }

    showHowTo = () => {
        this.props.setLocalState({ searchInput: '' });
        this.props.setResults('');
    }

    render = () => {
        let { inputValOnClose, selectedCount } = this.props;
        const noResults = (this.props.searchInput.length > 0) && (this.props.results.length == 0);
        selectedCount = selectedCount <= 0 ? this.props.results.length : selectedCount;
        return (
            <div className='result'>
                <div className='options'>
                    <button
                        type='button'
                        className={'move-to-quicklist move-to-quicklist--' +
                        (selectedCount < this.props.moveToQuickLimit && selectedCount > 0 ? 'green' : 'red')}
                        onClick={this.props.moveToQuicklist}
                    >
                        {`To Quicklist [${selectedCount}]`}
                    </button>
                    <label className='inp-check' htmlFor='sortByTime'>
                        <input
                            type='checkbox'
                            id='sortByTime'
                            checked={this.props.liveIPs}
                            onChange={this.props.changeLiveIPs}
                            data-option='sortByTime'
                        />
                        <span>Live IPs</span>
                    </label>
                    <div>
                        <button className='close-search' onClick={this.props.closeSearch}> CLOSE SEARCH</button>
                        <button className='close-search' onClick={this.showHowTo}>?</button>
                    </div>
                </div>
                {noResults && <div className='no-search-results'><span>NO SEARCH RESULTS</span></div>}
                {this.getContent(inputValOnClose)}
            </div>
        );
    }
}

export default SearchResults;
