import React from 'react';

class CustomError extends React.Component {
    render() {
        return (
            <div id='mother'>
                <div className={`invalid-license ${this.props.isMobile ? 'mobile' : ''}`}>
                    <div className='wrap'>
                        <img alt='cocopacket-logo' src='/less/img/cocopacket-logo_inverse.svg' />
                        <div className='box'>
                            Ups, something went wrong error.
                            <br />
                            <br />
                            {this.props.message}
                            <br />
                            <br />
                            <a rel='noopener' href='mailto:support@cocopacket.com' className='link'>
                                support@cocopacket.com
                            </a>
                            <br />
                            <div className='controls'>
                                <a href='/'>Go Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomError;
