/* global $:true */
import React from 'react';
import Utils from '../../js/utils.js';
import Loader from '../graph/loader.jsx';


class MainPanelLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: 'login',
            usernameInput: '',
            passInput: '',
            logging: false,
            wrongLogin: {},
        };
    }

    loginClick = (e) => {
        e.preventDefault();
        const username = this.state.usernameInput;
        const password = this.state.passInput;
        this.setState({ logging: true });
        const successCallback = data => this.props.logUser(true, data.isAdmin, username);
        const errorCallback = data => this.wrongLogin(data);
        const data = Utils.formApiData('/users', true, 'json', successCallback, errorCallback);
        data.contentType = 'application/json';
        data.data = { user: username, password };
        Utils.performPUTcall(data, 'POST');
    }

    wrongLogin = (err) => {
        const msg = Utils.getPropertyOfObject(['error'], err, '');
        const selector = msg.includes('password') ? 'password' : 'username';
        const obj = { selector, msg };
        this.setState({ wrongLogin: obj, logging: false });
    }

    inputChange = (key, val) => this.setState({ [key]: val, wrongLogin: {} });

    getLogin = () => {
        const showSSO = this.props.sso !== null;
        let btnContent = 'LOG IN';
        const { wrongLogin } = this.state;
        if (this.state.logging) {
            btnContent = (
                <>
                    <span>LOGGING IN</span>
                    <Loader />
                </>
            );
        }
        return (
            <div className='sub-content sub-content__login'>
                <span className='login__prompt'>{Utils.isSet(wrongLogin) ? wrongLogin.msg : ''}</span>
                <form className='sub-content--600' onSubmit={this.loginClick}>
                    <span className='input__span'>
                        <label className={wrongLogin.selector === 'username' ? 'input--error' : ''}>
                            USERNAME
                        </label>
                        <input
                            type='text'
                            value={this.state.usernameInput}
                            onChange={e => this.inputChange('usernameInput', e.target.value)}
                            className={wrongLogin.selector === 'username' ? 'input--error' : ''}
                            placeholder='username (e.g. admin)'
                        />
                    </span>
                    <span className='input__span'>
                        <label className={wrongLogin.selector === 'password' ? 'input--error' : ''}>
                            PASSWORD
                        </label>
                        <input
                            type='password'
                            value={this.state.passInput}
                            onChange={e => this.inputChange('passInput', e.target.value)}
                            className={wrongLogin.selector === 'password' ? 'input--error' : ''}
                            placeholder='password'
                        />
                    </span>
                    <div className='controls'>
                        <div className='controlLinks'>
                            {showSSO && (
                                <a className='link' href={this.props.sso}>
                                    Login via SSO
                                </a>
                            )}
                            <a className='link' onClick={() => this.setState({ content: 'forgot' })}>
                                Forgot your password?
                            </a>
                        </div>
                        <div className='butts'>
                            <button type='submit'>
                                {btnContent}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    getForgot = () => (
        <div className='sub-content sub-content__forgot'>
            <div className='sub-content--600'>
                <span className='title'>LOST YOUR PASSWORD?</span>
                <span className='desc'>Please enter the email address registered to your account </span>
                <span className='input__span'>
                    <label>EMAIL ADDRESS</label>
                    <input ref='forgot-email' placeholder='email address' required />
                </span>
                <div className='controls'>
                    <a className='link' data-view='login' onClick={this.changeState}>Back</a>
                    <div className='butts'>
                        <button type='button'>
                                RESET PASSWORD
                        </button>
                    </div>
                </div>
            </div>
            <div className='hot-fix'>
                <span>
                        We are working on automatic password resetting,
                        if you have problem with your password please email our support.
                    <br />
                    <br />
                        support@cocopacket.com
                </span>
                <div className='controls controls--center'>
                    <a className='link' data-view='login' onClick={() => this.setState({ content: 'login' })}>Back</a>
                </div>
            </div>
        </div>
    )

    render() {
        const content = this.state.content === 'forgot' ? this.getForgot() : this.getLogin();
        return (
            <div className='login'>
                <div className='content'>
                    <div className='logo'>
                        <a className='home' href='/'>
                            <img alt='cocopacket-logo' id='icon-logo' src='/less/img/cocopacket-logo_inverse.svg' />
                        </a>
                    </div>
                    {content}
                </div>
            </div>
        );
    }
}

export default MainPanelLogin;
