import React from 'react';
import Utils from '../../js/utils.js';
import Logs from '../../../changelog.json';

class ChangeLog extends React.Component {

    getEntries = () => {
        const versions = Object.keys(Logs).reverse();
        const content = versions.map(key => this.createEntry(key));
        return (
            <div className='changelog__entries'>
                <div className='timeline__series'></div>
                {content}
            </div>
        );
    }

    createEntry = (key) => {
        const version = key;
        const { date, commit, changes } = Logs[key];
        return (
            <div className='entry' key={key}>
                <div className='entry-line'>
                    <span className='entry-line__title'>Date:</span>
                    <span className='entry-line__value'>{date}</span>
                </div>
                <div className='entry-line'>
                    <span className='entry-line__title'>Version:</span>
                    <span className='entry-line__value'>{version}</span>
                </div>
                <div className='entry-line'>
                    <span className='entry-line__title'>Commit:</span>
                    <span className='entry-line__value'>{commit}</span>
                </div>
                <div className='entry-line'>
                    <span className='entry-line__title'>Changes:</span>
                </div>
                <div className='entry__body'>
                    {this.showDetail(changes)}
                </div>
            </div>
        );
    }

    showDetail = (changes) => {
        const delimeter = '->';
        let key = 0;
        const out = changes.map((str) => {
            const arr = str.split(delimeter);
            const type = arr[0].trim();
            const description = arr[1].trim();
            const content = type === 'FIX' ? this.parseFix(description) : this.parseDefault(description);
            const icon = this.getIcon(type);
            return (
                <span className='change__wrap' key={key++}>
                    {icon}
                    {content}
                </span>
            );
        });
        return out;
    }

    parseFix = (desc) => {
        const firstSplit = desc.split('R:');
        const secondSplit = firstSplit[1].split('S:');
        // ---
        const issue = firstSplit[0].replace('I:','');
        const reason = secondSplit[0];
        const solution = secondSplit[1];
        return (
            <span className='change__desc change__desc--multi'>
                <span className='entry-line'>
                    <span className='entry-line__title'>Issue:</span>
                    <span className='entry-line__value'>{issue}</span>
                </span>
                <span className='entry-line'>
                    <span className='entry-line__title'>Reason:</span>
                    <span className='entry-line__value'>{reason}</span>
                </span>
                <span className='entry-line'>
                    <span className='entry-line__title'>Solution:</span>
                    <span className='entry-line__value'>{solution}</span>
                </span>
            </span>
        );
    }

    parseDefault = (desc) => {
        return (
            <span className='change__desc'>{desc}</span>
        );
    }

    getIcon = (key) => {
        const list = this.getIcons();
        const selection = Utils.getPropertyOfObject([key], list, list.DEFAULT);
        return (
            <i className={'change__icon fa fa-' + selection.class} style={{ color: selection.color }} />
        );
    }

    getIcons = () => ({
        FIX: { class: 'wrench', color: 'red' },
        STYLE: { class: 'paint-brush', color: 'purple' },
        CHORE: { class: 'pencil-square-o', color: 'wheat' },
        REFACTOR: { class: 'bolt', color: 'gold' },
        FEATURE: { class: 'plus-square', color: '#32cd2e' }
    })

    getLegend = () => {
        const keys = Object.keys(this.getIcons());
        const out = keys.map((key) => {
            return (
                <span className='legend__item' key={key}>
                    {this.getIcon(key)}
                    {key}
                </span>
            );
        });
        return out;
    }

    render() {
        return (
            <div className='tab-content'>
                <span className='page-title'>
                    {this.props.name}
                </span>
                <div className='legend__wrap'>
                    {this.getLegend()}
                </div>
                <div className='changelog'>
                    {this.getEntries()}
                </div>
            </div>
        );
    }
}

export default ChangeLog;
