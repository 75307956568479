import React from 'react';

class SettingsBox extends React.Component {
    setLightTheme = () => {
        this.props.changeColor('light');
    }

    setDarkTheme = () => {
        this.props.changeColor('dark');
    }

    setTheme = () => {
        if (this.props.theme.label === 'light') {
            this.setDarkTheme();
        } else {
            this.setLightTheme();
        }
    }

    render() {
        const isLight = this.props.theme.label === 'light';
        return (
            <div className='settings-box'>
                <div className='switch'>
                    <span className={isLight ? 'active' : ''} onClick={this.setDarkTheme}>
                        Dark
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={this.setTheme} checked={isLight} />
                        <span className='slider round' />
                    </label>
                    <span className={isLight ? '' : 'active'} onClick={this.setLightTheme}>
                        Light
                    </span>
                </div>
            </div>
        );
    }
}

export default SettingsBox;
