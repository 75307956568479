import React from 'react';
import Clipboard from 'clipboard';
import Select2 from 'react-select2-wrapper';
import ModalWrapper from './modalWrapper.jsx';
import Utils from '../../js/utils.js';

class ShareUrlModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        const { graphType, ipDetails, generalSettings } = this.props;
        const probe = ipDetails.probe.split('=')[1];
        const epoch = Number(ipDetails.epoch);
        const localState = {
            activeTab: 'URL',
            displayAdditionalInfo: false,
            url: Utils.getPropertyOfObject(['public_url'], generalSettings, ''),
            key: null
        };
        const graphOptions = {
            graphType: graphType.toUpperCase(),
            epoch,
            graphInfo: 'INFO',
            selectedProbe: probe,
            ratio: 1,
            theme: Utils.getPropertyOfObject(['public_theme'], generalSettings, 'DARK'),
        };
        return { ...localState, ...graphOptions };
    }

    getKey = () => {
        // ----------------- API CALL GET -----------------
        const url = `/linkkey/${this.props.selectedIP}`;
        const successCallback = (data) => {
            const initState = this.getInitialState();
            initState.modalIsOpen = true;
            initState.key = data.key;
            this.setState(initState);
        };
        const data = Utils.formApiData(url, true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    getIframe = (ratio, url) => {
        const styleIframe = `-moz-transform: scale(${ratio},${ratio});`
            + `-webkit-transform: scale(${ratio},${ratio});`
            + `-o-transform: scale(${ratio},${ratio});`
            + `-ms-transform: scale(${ratio},${ratio});`
            + `transform: scale(${ratio},${ratio});`
            + '-moz-transform-origin: top left;'
            + '-webkit-transform-origin: top left;'
            + '-o-transform-origin: top left;'
            + '-ms-transform-origin: top left;'
            + 'transform-origin: top left;'
            + 'position: absolute;'
            + 'top: 0;'
            + 'left: 0;'
            + 'height: 600px;'
            + 'width: 100%;'
            + 'border: none;';
        let jsxs = '<div style="width: 600px; height: 600px; padding: 0; overflow: hidden;" >';
        jsxs += `<iframe src="${url}" style="${styleIframe}"/> </div>`;
        return jsxs.toLowerCase();
    }

    saveChanges = (e, callback) => {
        e.preventDefault();
        setTimeout(() => callback({ verdict: true }), 400);
    }

    changeState = (key, value) => this.setState({ [key]: value })

    displayAdditional = e => this.setState(prevState => ({ displayAdditionalInfo: !prevState.displayAdditionalInfo }));

    changeEpoch = (epoch) => {
        epoch = epoch == undefined ? 86400 : epoch * 3600;
        this.setState({ epoch });
    }

    generate = (isUrl = true) => {
        const { activeTab, url, key, graphType, epoch, selectedProbe, graphInfo, theme, ratio } = this.state;
        const { ipDetails } = this.props;
        const isIframe = activeTab === 'IFRAME';
        const withInfo = graphInfo === 'INFO';
        const customEpoch = Utils.getPropertyOfObject(['customEpoch'], ipDetails);
        const urlStart = url + '/#/ip=' + (isUrl ? key : '*key*');
        const params = [];
        params.push('type=' + graphType);
        params.push('epoch=' + epoch);
        if (customEpoch) {
            const end = Utils.getPropertyOfObject(['endPoint'], ipDetails);
            params.push('end=' + end);
        }
        params.push('slave=' + selectedProbe);

        // optional params
        params.push('info=' + (withInfo ? 'info' : 'noinfo'));
        params.push('iframe=' + (isIframe ? 'yes' : 'no'));
        params.push('theme=' + theme);

        // create output
        const completeUrl = urlStart + '&' + params.join('&');
        const encodedUrl = encodeURI(completeUrl).toLowerCase();
        if (isIframe) {
            return this.getIframe(ratio, encodedUrl);
        }
        return encodedUrl;
    }

    getSettingsContent = () => {
        const { activeTab, graphType, epoch, selectedProbe, graphInfo, theme } = this.state;
        const switches = [];
        switches.push(this.getSwitch('activeTab', activeTab === 'URL', ['URL', 'IFRAME']));
        switches.push(this.getSwitch('graphType', graphType === 'BASIC', ['BASIC', 'ADVANCED']));
        switches.push(this.getSwitch('graphInfo', graphInfo === 'INFO', ['INFO', 'NOINFO']));
        switches.push(this.getSwitch('theme', theme === 'LIGHT', ['LIGHT', 'DARK']));
        const titles = ['share via', 'graph type', 'graph info', 'graph theme'];
        const wrapSwitch = (title, switchElement) => (
            <div className='line' key={title}>
                <div className='title'><span>{title}</span></div>
                {switchElement}
            </div>
        );
        const wrappedSwitches = switches.map((obj, i) => wrapSwitch(titles[i], obj));
        return (
            <div className='rest'>
                <div className='line high'>
                    <div className='title'>
                        <span> graph time range (hours)</span>
                    </div>
                    <div className='set-option'>
                        <input ref='epochInput'
                               type='text'
                               name='epoch'
                               value={epoch/3600}
                               onChange={e => this.changeEpoch(e.target.value)}
                        />
                    </div>
                </div>
                {this.getProbesSelect(selectedProbe)}
                {wrappedSwitches}
            </div>
        );
    }

    getSwitch = (key, isLeftValSelected, values) => {
        const onChangeHandler = () => {
            const newValue = isLeftValSelected ? values[1] : values[0];
            this.setState({ [key]: newValue });
        };
        const leftValue = values[0];
        const rightValue = values[1];
        return (
            <div className='set-option' key={key}>
                <div className='switch'>
                    <span className={'left-span' + (isLeftValSelected ? ' active' : '')}
                          onClick={() => this.changeState(key, leftValue)}
                    >
                        {leftValue}
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={onChangeHandler} checked={!isLeftValSelected} />
                        <span className='slider round' />
                    </label>
                    <span className={isLeftValSelected ? '' : ' active'} onClick={() => this.changeState(key, rightValue)}>
                        {rightValue}
                    </span>
                </div>
            </div>
        );
    };

    getProbesSelect = (selectedProbe) => {
        const { probesList } = this.props;
        const dataOptions = probesList.map((key) => {
            const probe = key.toUpperCase();
            return { text: probe, id: probe };
        });
        return (
            <div className='line high'>
                <div className='title'>
                    <span>select probe</span>
                </div>
                <div className='set-option'>
                    <Select2
                        className='probes-select'
                        data={dataOptions}
                        options={{ minimumResultsForSearch: 'Infinity' }}
                        onSelect={e => this.setState({ selectedProbe: e.target.value })}
                        value={selectedProbe}
                    />
                </div>
            </div>
        );
    }

    copyEffect(e) {
        const selector = $(e.target);
        selector.css('color', '#19e880');
        selector.text('Link copied');
        setTimeout(() => {
            selector.text('Share');
            selector.css('color', '#ADC65C');
        }, 2000);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.triggeredFromMobile && this.props.ipDetails != nextProps.ipDetails) {
            const details = Utils.getPropertyOfObject(['ipDetails'], nextProps, {});
            this.setState({
                epoch: details.epoch,
                graphType: details.graphType.toUpperCase(),
                selectedProbe: details.probe.split('=')[1]
            });
        }
        if (this.props.triggeredFromMobile && this.props.selectedIP != nextProps.selectedIP) {
            this.setState({ key: null });
        }
    }

    componentDidMount() {
        const selector = '.copy-btn-url';
        const clipboard = new Clipboard(selector, { text: this.generate });
    }

    render() {
        const styles = { content: { maxWidth: '1000px', height: 'auto' } };
        const { displayAdditionalInfo, url } = this.state;
        const { triggeredFromMobile, selectedIP  } = this.props;
        if (triggeredFromMobile && !this.state.key) { this.getKey(); }
        const Trigger = ({ action }) => {
            const classes = ['cntrl-btn', 'share-btn'];
            if (triggeredFromMobile) { classes.push('copy-btn-url'); }
            const onClickAction = triggeredFromMobile ? this.copyEffect : action;
            return (
                <a className={classes.join(' ')} onClick={onClickAction}>
                    <i className='fa fa-share-square-o' />
                    Share
                </a>
            );
        };
        const renderComponent = (confirmCallback) => (
            <form onSubmit={e => this.saveChanges(e, confirmCallback)}>
                <h2>{`Share IP ${selectedIP}`}</h2>
                <h3>Create an url link to share this graph with others.</h3>
                <div className='content'>
                    <label className='label-title'><span>PUBLIC SERVER</span></label>
                    <input
                           type='text'
                           value={url}
                           autoComplete='off'
                           onChange={e => this.setState({ url: e.target.value })}
                    />
                    <a className='more-trigger' onClick={this.displayAdditional}>
                        More settings
                        <i className={`fa fa-chevron-${displayAdditionalInfo ? 'down' : 'up'}`} />
                    </a>
                    {displayAdditionalInfo && this.getSettingsContent()}
                </div>
                <span className='keySpan'>
                    URL:
                    <span className='val'>{this.generate(false)}</span>
                </span>
                <div className='butts'>
                    <button type='submit' className='modal-confirm copy-btn copy-btn-url'>
                        Save link to clipboard
                    </button>
                </div>
            </form>
        );
        return (
            <ModalWrapper
                Trigger={Trigger}
                style={styles}
                render={renderComponent}
                modalID='modal-public-url'
                customWrapClass='set-ip-header share-ip'
                openModal={this.getKey}
                successPrompt='Share link is in your clipboard :)'
            />
        );
    }
}

export default ShareUrlModal;
