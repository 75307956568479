/* global Chart:true */
import React from 'react';
import Utils from '../../../js/utils.js';

class AsPath extends React.PureComponent {
    getAsPoints = () => {
        const colors = Chart.colorField;
        const asArray = [];
        const { asPath } = this.props;
        asPath.forEach((as, i) => {
            let colorIndex = as.pl / 10;
            colorIndex = Math.ceil(colorIndex);
            colorIndex = colorIndex >= colors.length ? colors.length - 1 : colorIndex;
            const style = { color: colors[colorIndex], border: `solid 1.5px ${colors[colorIndex]}` };
            const color = { color: colors[colorIndex] };
            const asNum = as.name == -1 ? '???' : as.name;
            asArray.push(
                <li key={i} style={style}>
                    {asNum !== '???' &&
                        <a href={'https://as.cocopacket.com/AS' + asNum} style={color} target='_blank' rel='noopener noreferrer'>
                            {asNum}
                        </a>
                    }
                    { asNum === '???' && asNum }
                </li>,
            );
        });
        return asArray;
    }

    render() {
        return (
            <div className='aspath'>
                {!this.props.isMobile &&
                    <>
                        <div className='aspath__row'>
                            <span className='title'>LAST AS PATH: </span>
                            <ul>
                                {Utils.isSet(this.props.asPath) && this.getAsPoints()}
                            </ul>
                        </div>
                        <div className='aspath__row'>
                            <span className='aspath__cta'>
                                click AS to find out more information
                            </span>
                        </div>
                    </>
                }
                {this.props.isMobile &&
                    <>
                        <span className='title'>LAST AS PATH: </span>
                        <ul>
                            {Utils.isSet(this.props.asPath) && this.getAsPoints()}
                        </ul>
                    </>
                }
            </div>
        );
    }
}

export default AsPath;
