import React from 'react';
import Utils from '../../js/utils.js';
import CategorySelection from '../common/CategorySelection.jsx';

class SettingsCategories extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.setCategory = this.setCategory.bind(this);
        this.getContent = this.getContent.bind(this);
        this.getControls = this.getControls.bind(this);
        this.saveThresholds = this.saveThresholds.bind(this);
        this.setActionResponse = this.setActionResponse.bind(this);
        this.getInputValues = this.getInputValues.bind(this);
        this.getCategories = this.getCategories.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = { category: null, action: null };
    }

    setCategory(e) {
        const cat = e.target.value;
        this.setState({
            category: cat.toLowerCase(),
        });
    }

    getContent() {
        const cat = this.state.category;
        const key = Utils.isSet(cat) ? cat : 'default';
        let data = this.props.data.category_thresholds[key];
        if (data == undefined) {
            data = this.props.data.category_thresholds.default;
        }
        return (
            <div key={cat} className='side-by-side'>
                <div className='side'>
                    <span className='subsubtitle'> Packet Loss </span>
                    <span className='line'>
                        <label className='label-title'>Change (%):</label>
                        <input type='text' defaultValue={data[0]} className='threshold-input' ref='lostPercent' />
                    </span>
                    <span className='line'>
                        <label className='label-title'>Minimal change (%):</label>
                        <input type='text' defaultValue={data[1]} className='threshold-input' ref='lostMinPercentChange' />
                    </span>
                    <span className='line'>
                        <label className='label-title'>Threshold (%):</label>
                        <input type='text' defaultValue={data[2]} className='threshold-input' ref='lostPercentTreshold' />
                    </span>
                </div>
                <div className='side'>
                    <span className='subsubtitle'> Latency </span>
                    <span className='line'>
                        <label className='label-title'>Change (%):</label>
                        <input type='text' defaultValue={data[3]} className='threshold-input' ref='latPercent' />
                    </span>
                    <span className='line'>
                        <label className='label-title'>Minimal change (ms):</label>
                        <input type='text' defaultValue={data[4]} className='threshold-input' ref='latMinMsChange' />
                    </span>
                    <span className='line'>
                        <label className='label-title'>Threshold (%):</label>
                        <input type='text' defaultValue={data[5]} className='threshold-input' ref='latMsTreshold' />
                    </span>
                </div>
            </div>
        );
    }

    getControls() {
        const buttState = this.state.action;
        const color = buttState == 'OK' ? 'green' : buttState == 'EROR' ? 'red' : 'transparent';
        const text = buttState == 'OK' ? 'DONE !' : buttState == 'EROR' ? 'ERROR' : 'SAVE';
        const style = { border: `solid ${color}` };
        return (
            <div className='butts'>
                <button type='submit' style={style} className='butt-confirm' onClick={this.saveThresholds}>
                    {text}
                </button>
            </div>
        );
    }

    saveThresholds() {
        let key = this.state.category;
        key = Utils.isSet(key) ? key : 'default';
        this.props.data.category_thresholds[key.toLowerCase()] = this.getInputValues();
        this.props.saveCategories(this.setActionResponse);
    }

    setActionResponse(result) {
        const scope = this;
        scope.setState({ action: result });
        setTimeout(() => {
            scope.setState({ action: 'NO' });
        }, 1500);
    }

    getInputValues() {
        const arr = [];
        arr.push(Number(this.refs.lostPercent.value));
        arr.push(Number(this.refs.lostMinPercentChange.value));
        arr.push(Number(this.refs.lostPercentTreshold.value));
        arr.push(Number(this.refs.latPercent.value));
        arr.push(Number(this.refs.latMinMsChange.value));
        arr.push(Number(this.refs.latMsTreshold.value));
        return arr;
    }

    getCategories() {
        const data = JSON.parse(JSON.stringify(this.props.categories));
        delete data.I_P_S;
        delete data.FAVOURITES;
        return data;
    }

    render() {
        return (
            <div className='tab-content'>
                <p>
                    Dashboard helps you out to find issues in your network. It uses a specially developed search engine
                    that is capable of detecting undesired behavior.
                    <br />
                    <br />
                    Dashboard's incident engine can be greatly optimized by your input. Sorting IPs into different
                    categories not only helps you keep your IPs organized, but it also improves incident recognition.
                    <br />
                    <br />
                    Override default threshold values with your custom ones. Specifying threshold values improves
                    incident's relevance. Let our algorithm detect only incidents you are interested in.
                    <br />
                    <br />
                    <span className='highlight-blue'>Change</span>
                    <br />
                    Percentual change in packet loss or latency compared to a previous epoch's average values. Epoch is defined in dashboard's header.

                    <br />
                    <br />
                    <span className='highlight-blue'>Minimal change</span>
                    <br />
                    Minimal change serves to filter out incidents that are percentually big (bigger than change threshold) but in real values
                    are omittable.
                    <br />
                    <i>example: change from 0.1ms to 0.2ms</i>

                    <br />
                    <br />
                    <span className='highlight-blue'>Threshold:</span>
                    <br />
                    Everything that exceeds threshold value will be detected as an incident value.
                </p>
                <CategorySelection
                    category={this.state.category}
                    categories={this.getCategories()}
                    setCategory={this.setCategory}
                    dropdownMode
                />
                {Utils.isSet(this.props.data) && this.getContent()}
                {this.getControls()}
            </div>
        );
    }
}

export default SettingsCategories;
