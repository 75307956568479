import React from 'react';
import SVGInline from 'react-svg-inline';
import iconSearch from '../../../../less/libs/icons/icon-search.svg';

class SearchInput extends React.Component {
    onFocusIn = () => {
        if (this.props.isMobile || this.props.results.length > 0) {
            this.props.setLocalState({ showOptions: true });
        }
    }

    onInputChange = (e) => {
        const value = e.target.value.toString().toLowerCase();
        this.props.setLocalState({ inputValOnClose: '', searchInput: value, showOptions: true });
        this.props.setResults(value);
    }

    render() {
        return (
            <>
                <SVGInline svg={iconSearch} className='icon-search' />
                <input
                    type='text'
                    placeholder='Search IP...'
                    className={this.props.results.length === 0 ? 'redBorder' : ''}
                    onFocus={this.onFocusIn}
                    onChange={this.onInputChange}
                    value={this.props.searchInput}
                    autoFocus={!this.props.isMobile}
                />
            </>
        );
    }
}

export default SearchInput;
