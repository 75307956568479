import React from 'react';
import { withTooltip } from 'react-tippy';
import SVGInline from 'react-svg-inline';
import Utils from '../../js/utils.js';
import ModalWrapper from './modalWrapper.jsx';
import iconInfo from '../../../less/libs/icons/icon-info.svg';

class ExcludeAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        data: {},
        inputValidation: null,
    })

    openModal = () => {
        this.setState({ ...this.getInitialState() });
        this.loadData();
    }

    loadData = () => {
        const url = '/maintenance';
        const successCallback = data => this.setState({ data });
        const data = Utils.formApiData(url, true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    validate = () => {
        const { data } = this.state;
        const textVal = data.toString().replace(/\ /g,'');
        const arr = textVal.split(',');
        const v4reg = Utils.getIpRegex(true);
        const v6reg = Utils.getIpRegex(false);
        let validation = true;
        const validatedArr = arr.filter(ip => ip.length > 1 && (v4reg.test(ip) || v6reg.test(ip)));
        return { isValid: arr.length === validatedArr.length, data: validatedArr };
    }

    saveChanges = (e, confirmCallback) => {
        e.preventDefault();
        let content = this.state.data;
        const data = this.validate(content);
        const url = '/maintenance';
        const successCallback = (succ) => { confirmCallback({ verdict: true }); };
        const errorCallback = (err) => { confirmCallback({ verdict: false, errorPrompt: err.error }); };
        const call = Utils.formApiData(url, true, 'json', successCallback, errorCallback);
        call.contentType = 'application/json';
        call.data = data.data;
        Utils.performPUTcall(call);
    }


    render = () => {
        const InfoIcon = () => (<SVGInline svg={iconInfo} />);
        const tooltipOptions = {
            style: { marginLeft: '10px' }, theme: 'dark', position: 'right', animation: 'perspective',
        };
        tooltipOptions.title = `IP list must respect a string format with "," delimiter. 
                                    \n (e.g. 1.1.1.1,2.2.2.2,3.3.3.3)
                                    \n IPv6 is supported`;

        const Tooltip = withTooltip(InfoIcon, { ...tooltipOptions });
        const styles = { content: { maxWidth: '1000px', height: 'auto' } };
        const Trigger = ({ action }) => (
            <a onClick={action} className='add-exclude modal-trigger'>
                <i className='fa fa-plus' />
                Exclude
            </a>
        );
        const renderComponent = confirmCallback => (
            <form onSubmit={e => this.saveChanges(e, confirmCallback)}>
                <h2>Maintenance</h2>
                <p>
                    Add IPs in the list to avoid alert triggering.
                    <br />
                    This could be used for scheduled maintenance or simply to turn off alerts for specific IPs.
                </p>
                <div className='content-wrap'>
                    <label>
                        Exclude list
                        <Tooltip />
                    </label>
                    <textarea
                        type='text'
                        value={this.state.data.toString()}
                        className={(this.state.inputValidation ? 'red-border' : '')}
                        onChange={e => this.setState({ data: e.target.value })}
                    >
                        {JSON.stringify(this.state.data)}
                    </textarea>
                </div>
                <div className='btn-wrap'>
                    <button type='submit' className='btn modal-confirm'>Save</button>
                </div>
            </form>
        );
        return (
            <ModalWrapper
                Trigger={Trigger}
                style={styles}
                modalID='modal-add-exclude'
                openModal={this.openModal}
                render={renderComponent}
            />
        );
    }
}

export default ExcludeAlert;
