import React, { useEffect } from 'react';
import { withTooltip } from 'react-tippy';
import Clipboard from 'clipboard';
import Utils from '../../../js/utils';

const IpVersionLabel = ({ ipv4, ipv6 }) => {
    const tooltipOptions = {
        style: { marginLeft: '0px' },
        theme: 'dark',
        position: 'right',
        animation: 'perspective',
    };

    // IP version 4 tooltip
    const hasIpv4 = Utils.isSet(ipv4);
    const V4Icon = () => (<span data-clipboard-text={ipv4} className={'ipv ipv--' + (hasIpv4 ? 'green' : 'red')}>4</span>);
    let options = { ...tooltipOptions, title: 'IPv4 source: ' + ipv4 + ' (click to copy)' };
    const TooltipV4 = withTooltip(V4Icon, options);

    // IP version 6 tooltip
    const hasIpv6 = Utils.isSet(ipv6);
    const V6Icon = () => (<span data-clipboard-text={ipv6} className={'ipv ipv--' + (hasIpv6 ? 'green' : 'red')}>6</span>);
    options = { ...tooltipOptions, title: 'IPv6 source: ' + ipv6 + ' (click to copy)' };
    const TooltipV6 = withTooltip(V6Icon, options);

    useEffect(() => { const clip = new Clipboard('.ipv'); });

    return (
        <>
            <TooltipV4 />
            <TooltipV6 />
        </>
    );
};

export default IpVersionLabel;
