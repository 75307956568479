import React from 'react';
import SVGInline from 'react-svg-inline';
import PagePicker from './pagePicker.jsx';
import DetailPanel from './detailPanel.jsx';
import DetailOverviewPanel from './detailOverView.jsx';
import iconChevronDown from '../../../less/libs/icons/chevron_down.svg';
import Banner from '../common/Banner.jsx';
import Utils from '../../js/utils.js';


class TopPanel extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getIpList = this.getIpList.bind(this);
        this.getContextPanel = this.getContextPanel.bind(this);
        this.state = { showBanner: true };
    }

    getLogo() {
        let imgSrc = '/less/img/cocopacket-logo_inverse.svg';
        if (this.props.view == 'detail' && this.props.theme.label === 'light') {
            imgSrc = '/less/img/cocopacket-logo.png';
        }
        return (
            <a className='home' href='/'>
                <img id='icon-logo' src={imgSrc} />
            </a>
        );
    }

    getIpList() {
        if (!this.props.view.includes('detail')) {
            return;
        }
        const direction = this.props.sidePanelStatus ? 'down' : 'up';
        return (
            <button className='ip-list' onClick={this.props.triggerSidebarAction}>
                <span className='ip-list--left'>IPs</span>
                <span className='ip-list--right'>
                    <SVGInline svg={iconChevronDown} className={`chevron ${direction}`} />
                </span>
            </button>
        );
    }

    getContextPanel() {
        if (this.props.view == 'detail') {
            return <DetailPanel key='page-detail' {...this.props} />;
        } if (this.props.view.includes('detail')) {
            return <DetailOverviewPanel key='page-overview' {...this.props} />;
        }
    }

    render() {
        const { license } = this.props;
        const rightContent = [];
        if (Utils.isSet(license) && license.isSoftExceeded && this.state.showBanner) {
            rightContent.push(
                <Banner
                    key='banner'
                    license={license}
                    hideBanners={() => {
                        this.setState({ showBanner: false });
                    }}
                />,
            );
        } else {
            rightContent.push(this.getContextPanel());
            rightContent.push(
                <PagePicker
                    key='pagepicker'
                    view={this.props.view}
                    setStateView={this.props.setStateView}
                />,
            );
        }
        return (
            <div className='top-panel'>
                <div className='left'>
                    {this.getLogo()}
                    {this.getIpList()}
                </div>
                {rightContent}
            </div>
        );
    }
}

export default TopPanel;
