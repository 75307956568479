import React from 'react';
import Utils from '../../js/utils.js';
import SettingsCategories from '../settings/settings.categories.jsx';
import SettingsVersion from '../settings/settings.version.jsx';
import SettingsPassword from '../settings/settings.password.jsx';
import SettingsGlobal from '../settings/settings.global.jsx';
import SettingsUsers from '../settings/settings.users.jsx';
import SettingsBilling from '../settings/settings.billing.jsx';
import SettingsAlerts from '../settings/alerts/settings.alerts.jsx';
import SettingsStatus from '../settings/status/settings.status.jsx';
import Changelog from '../settings/settings.changelog.jsx';
import Serverlog from '../settings/settings.log.jsx';
import TopPanel from '../topPanel/topPanel.jsx';

class MainPanelSettings extends React.Component {
    constructor(props) {
        super(props);
        this.getContent = this.getContent.bind(this);
        this.getSidePanel = this.getSidePanel.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = { data: {}, activeTab: 'PROBES / STATUS' };
    }

    loadData = () => {
        const successCallback = data => this.setState({ data });
        const data = Utils.formApiData('/settings', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    changeTab = activeTab => this.setState({ activeTab });

    getContent() {
        const view = this.state.activeTab;
        switch (view) {
        case 'GLOBAL SETTINGS':
            return (
                <SettingsGlobal
                    data={this.state.data}
                    setSettings={this.setSettings}
                    name={view}
                    user={this.props.user}
                />
            );
        case 'CATEGORIES SETUP':
            return (
                <SettingsCategories
                    categories={this.props.categories}
                    selectedIP={this.props.selectedIP}
                    data={this.state.data}
                    saveCategories={this.setSettings}
                    name={view}
                />
            );
        case 'PASSWORD':
            return (<SettingsPassword name={view} username={this.props.username} />);
        case 'USERS':
            return (
                <SettingsUsers
                    name={view}
                    username={this.props.username}
                    isRoot={this.props.isRoot}
                />
            );
        case 'ALERTS':
            return (<SettingsAlerts name={view} isRoot={this.props.isRoot} />);
        case 'BILLING':
            return (<SettingsBilling name={view} />);
        case 'PROBES / STATUS':
            return (<SettingsStatus setHash={false} name={view} isRoot={this.props.isRoot} {...this.props} />);
        case 'ABOUT':
            return (
                <SettingsVersion
                    name={view}
                    user={this.props.user}
                    config={this.props.config.ping.ips}
                    isRoot={this.props.isRoot}
                />
            );
        case 'CHANGELOG':
            return (<Changelog name={view} />);
        case 'SERVERS LOG':
            return (<Serverlog name={view} />);
        }
    }

    setSettings = (newData, callbacks) => {
        const out = { ...this.state.data, ...newData };
        this.setState({ data: out });
        this.setSettingsCall(out, callbacks);
    }

    setSettingsCall(calldata, callbacks) {
    // ----------------- API CALL PUT -----------------
        const data = Utils.formApiData('/settings', true, 'json', callbacks.successCallback, callbacks.errorCallback);
        data.contentType = 'application/json';
        data.data = calldata;
        Utils.performPUTcall(data);
    }

    getSidePanel() {
        const tabs = [];
        const names = [
            'PROBES / STATUS',
            'GLOBAL SETTINGS',
            'ALERTS',
            'PASSWORD',
            'CATEGORIES SETUP',
            'USERS',
            'BILLING',
            'ABOUT',
            'CHANGELOG',
            'SERVERS LOG'

        ];
        const { isRoot } = this.props;
        const isVisible = [true, isRoot, isRoot, true, false, isRoot, false, true, true, true];
        const logos = ['cog', 'circle-o', 'bell', 'key', 'list-ul', 'user-plus', 'file-text-o', 'info-circle', 'archive', 'server'];
        names.forEach((key, index) => {
            if (isVisible[index]) {
                tabs.push(
                    <li key={index} className={this.state.activeTab === key ? 'active' : ''}>
                        <a onClick={() => this.changeTab(key)}>
                            <i className={`fa fa-${logos[index]}`} />
                            {key}
                        </a>
                    </li>,
                );
            }
        });
        return (
            <div className='side-panel'>
                <ul className='options'>
                    {tabs}
                </ul>
            </div>
        );
    }

    componentWillMount() {
        Utils.setHashSilently('settings');
        this.loadData();
    }

    render() {
        let mainContent = (
            <>
                { this.getSidePanel() }
                { this.getContent() }
            </>
        );
        if (this.props.isMobile) {
            const style = { margin: '0 auto', width: '80%', marginTop: '35%' };
            mainContent = (<h2 style={style}> Settings is only allowed on a desktop browser.</h2>);
        }
        return (
            <div id='mother' className={this.props.isMobile ? 'mobile' : ''}>
                <TopPanel
                    changeTab={this.changeTab}
                    {...this.props}
                    view='settings'
                />
                <div className='main-panel settings side-relative'>
                    { mainContent }
                </div>
            </div>
        );
    }
}

export default MainPanelSettings;
