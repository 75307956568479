import React from 'react';
import Utils from '../../js/utils.js';

class SettingsBilling extends React.PureComponent {
    render() {
        return (
            <div className='tab-content info'>
                <span className='page-title'>
                    {' '}
                    {this.props.name}
                    {' '}
                </span>
                <span>
                    Billing version:
                    <span className='val'> no - billing - profile </span>
                </span>
                <span>
                    Billing info:
                    <span className='val'> no - billing profile </span>
                </span>
            </div>
        );
    }
}

export default SettingsBilling;
