import React from 'react';
import Select2 from 'react-select2-wrapper';
import Utils from '../../js/utils';
import Navigator from './navigator.jsx';

/** *
 * PROPS
 * dropdownMode - [bool] view style decision
 * catList - [arr] - ['food','fruit','apple'] - list of categories and subcategories (left to right)
 * categories - [obj] - {cat-one:{cat-one-sub:{...,I_P_S:[]}, I_P_S:[]}}
 * modalIsOpen - [bool]
 * selectedIP - [str] - '127.0.0.1'
 */

class CategorySelection extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getNavigator = this.getNavigator.bind(this);
        this.setNaviState = this.setNaviState.bind(this);
        this.getDropdownCategories = this.getDropdownCategories.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        if (this.props.dropdownMode) {
            return {};
        }
        return {
            newCategories: {},
            catSelection: [...this.props.catList],
        };
    }

    getNavigator() {
        if (this.props.dropdownMode) {
            return this.getDropdownCategories();
        }
        const data = this.iterateThroughCategories(this.props.categories, this.state.catSelection);
        const sortedKeys = Utils.sortKeys(data);
        const dataOptions = [{ text: '-', id: '-' }];
        if (Utils.isSet(sortedKeys)) {
            sortedKeys.forEach((key) => {
                if (key != 'I_P_S' && key != 'FAVOURITES' && key != '__REPORT') {
                    dataOptions.push({ text: key.toUpperCase(), id: key });
                }
            });
        }
        return (
            <div className='select-category-wrap'>
                <span className='label-title'> Select category </span>
                <Navigator
                    selection={this.state.catSelection}
                    dataOptions={dataOptions}
                    setNaviState={this.setNaviState}
                />
            </div>
        );
    }

    setNaviState(selection) {
        this.setState({ catSelection: selection });
        this.props.setCategory(selection);
    }

    getDropdownCategories() {
        // DROPDOWN MODE
        const dataOptions = [];
        const categoryKeys = Object.keys(this.props.categories);
        dataOptions.push({ text: 'DEFAULT', id: 'DEFAULT' });
        categoryKeys.forEach(key => dataOptions.push({ text: key.toLocaleUpperCase(), id: key }));
        let sel = this.props.category;
        if (Utils.isNotSet(sel)) {
            sel = 'Default';
        }
        sel = sel.toLocaleUpperCase();
        const options = {
            minimumResultsForSearch: Infinity,
            placeholder: sel,
        };
        return (
            <div className='select-wrap category-dropdown'>
                <span className='left-title'> SELECT CATEGORY </span>
                <Select2
                    options={options}
                    className='select-box'
                    data={dataOptions}
                    onChange={this.props.setCategory}
                    id='categoryInput'
                />
            </div>
        );
    }

    iterateThroughCategories = (data, list) => {
        let iterate = data;
        if (Utils.isNotSet(data)) {
            return {};
        }
        list.forEach((key) => {
            if (iterate[key] == null) {
                return {};
            }
            iterate = iterate[key];
        });
        return iterate;
    }

    componentWillReceiveProps(nextProps) {
        // modal was open and closed, reset selection to its true nature
        if (nextProps.modalIsOpen != this.props.modalIsOpen) {
            this.setState({
                catSelection: [...nextProps.catList],
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.dropdownMode) {
            return false;
        }
        if (this.props.selectedIP != nextProps.selectedIP
            || this.state != nextState) {
            const regex = Utils.getIpRegex();
            if (regex.test(nextProps.selectedIP)) {
                return true;
            }
        }
        return this.state != nextState;
    }

    render() {
        return (
            <div className='category-wrap'>
                {this.getNavigator()}
            </div>
        );
    }
}

export default CategorySelection;
