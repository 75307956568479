import React from 'react';
import { withTooltip } from 'react-tippy';
import Utils from '../../../js/utils.js';
import ColorLegend from './colorLegend.jsx';

class GraphFooter extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getStats = this.getStats.bind(this);
        this.getColorLegend = this.getColorLegend.bind(this);
    }

    getStats() {
        const stats = [];
        if (Utils.isSet(this.props.graphData)) {
            const data = this.props.graphData.latency;
            if (Utils.isSet(data)) {
                stats.push(
                    <span key='0' className='span-info latency-max'>
                        MAX:
                        <span className='val'>
                            {data.max}
                            ms
                        </span>
                    </span>,
                );
                stats.push(
                    <span key='1' className='span-info latency-min'>
                        MIN:
                        <span className='val'>
                            {data.min}
                            ms
                        </span>
                    </span>,
                );
            }
        }
        if (Utils.isSet(this.props.graphData)) {
            const data = this.props.graphData.packets;
            if (Utils.isSet(data)) {
                const theroryCount = Math.floor(this.props.ipDetails.epoch / 2);
                const InfoIcon = () => (<i className='fa fa-info-circle' />);
                const tooltipOptions = {
                    style: { marginLeft: '10px' }, theme: 'dark', position: 'right', animation: 'perspective',
                };
                tooltipOptions.title = 'Coloring signalizes the count difference between real ping count and theoretical. ' +
                    'Yellow and red color indicates a possible problem with your Probe. ' +
                    'Non-green color implies your probe is not able to collect all the data it is supposed to. ' +
                    'The theoretical count for your current graph is ' + theroryCount;
                const Tooltip = withTooltip(InfoIcon, { ...tooltipOptions });
                let losInPerc = data.total > 0 && data.total_loss > 0 ? Number(data.total_loss / (data.total / 100)) : 0;
                losInPerc = losInPerc.toFixed(2);
                const colorLoss = Utils.getLossColor(losInPerc);
                const style = { color: colorLoss };
                const ratio = (data.total / theroryCount) * 100; // ratio how many data coco has for this ip
                let colorRatio = ratio > 90 ? 'RGBA(18, 182, 88, 1.00)' : 'RGBA(174, 196, 101, 1.00)';
                colorRatio = ratio < 60 ? 'RGBA(244, 76, 78, 1.00)' : colorRatio;
                stats.push(
                    <span key='2' className='span-info packet-total'>
                        TOTAL:
                        <span className='val' style={{ color: colorRatio }}>{Utils.formatNumber(data.total, 0) }</span>
                        <Tooltip />
                    </span>,
                );
                stats.push(
                    <span key='3' className='span-info packet-total'>
                        LOSS:
                        <span className='val'>{Utils.formatNumber(data.total_loss, 0)}</span>
                    </span>,
                );
                stats.push(
                    <span key='4' className='span-info packet-total'>
                        <span className='val' style={style}>{`${losInPerc}%`}</span>
                    </span>,
                );
            }
        }
        return (
            <div className='footer-block stat-block'>
                {stats}
            </div>
        );
    }

    getColorLegend() {
        if (this.props.isMobile) {
            return;
        }
        return (
            <div className='footer-block legend-block'>
                <ColorLegend
                    graphData={this.props.graphData}
                    stats={this.props.stats}
                    pxWidth={this.props.pxWidth}
                    chart={this.props.chart}
                    graphType={this.props.ipDetails.graphType}
                    accuracy={this.props.ipDetails.accuracy}
                />
            </div>
        );
    }

    render() {
        const count = Utils.getPropertyOfObject(['stats', 'count', 'count'], this.props, 0);
        return (
            <div className='footer'>
                {count > 0 &&
                    <>
                        {this.getColorLegend()}
                        {this.getStats()}
                    </>}
            </div>
        );
    }
}

export default GraphFooter;
