/* eslint-disable */
import React from 'react';
import Select2 from 'react-select2-wrapper';
import Utils from '../../js/utils';

class DashboardControlHeader extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.createDropdownType = this.createDropdownType.bind(this);
        this.createDropdownSlaves = this.createDropdownSlaves.bind(this);
        this.createDropdownTime = this.createDropdownTime.bind(this);
        this.getDropdownCategories = this.getDropdownCategories.bind(this);
        this.setCategories = this.setCategories.bind(this);
        this.setEpoch = this.setEpoch.bind(this);
        this.setSlaves = this.setSlaves.bind(this);
        this.setType = this.setType.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    /**
     * Creates the dropdown selectbox (all issues, latency, packet loss). Provides the possibility to filter incidents
     * byt incident type.
     *
     * @returns {XML}
     */
    createDropdownType() {
        const dataOptions = [
            { text: 'ALL ISSUES', id: 'ALL' },
            { text: 'LATENCY', id: 'LAT' },
            { text: 'PACKET LOSS', id: 'PL' },
        ];
        const options = {
            minimumResultsForSearch: Infinity,
        };
        return (
            <div className='select-wrap '>
                <Select2
                    options={options}
                    className='select-box'
                    data={dataOptions}
                    onSelect={this.setType}
                    defaultValue='ALL'
                />
            </div>
        );
    }

    /**
     * Create dropdown to filter out concrete slave, it creates the list of all slaves during creating entries.
     *
     * @returns {XML}
     */
    createDropdownSlaves() {
        const dataOptions = [{ text: 'ALL SLAVES', id: 'ALL' }];
        const data = this.props.slaveList;
        for (const index in data) {
            const probe = index.toUpperCase();
            dataOptions.push(
                { text: probe, id: probe },
            );
        }
        const options = {
            minimumResultsForSearch: Infinity,
        };
        return (
            <div className='select-wrap'>
                <Select2
                    options={options}
                    className='select-box'
                    data={dataOptions}
                    onSelect={this.setSlaves}
                    defaultValue='ALL'
                />
            </div>
        );
    }

    /**
     * Create dropdown select box for epoch selection
     *
     * @returns {XML}
     */
    createDropdownTime() {
        const dataOptions = [
            { text: '1 Hour', id: '1' },
            { text: '3 Hours', id: '3' },
            { text: '6 Hours', id: '6' },
            { text: '12 Hours', id: '12' },
            { text: '1 Day', id: '24' },
            { text: '2 Days', id: '48' },
            { text: '3 Days', id: '72' },
            { text: '1 Week', id: '120' },
        ];
        const options = {
            minimumResultsForSearch: Infinity,
        };
        return (
            <div className='select-wrap time-input'>
                <Select2
                    options={options}
                    className='select-box'
                    data={dataOptions}
                    onChange={this.setEpoch}
                    defaultValue='24'
                />
            </div>
        );
    }

    /**
     * Create dropdown select box for category selection. For each change the ajax call is triggered and new
     * data are fetched from the backend.
     *
     * @returns {XML}
     */
    getDropdownCategories() {
        const dataOptions = [];
        const data = this.props.categories;
        const sortedKeys = Utils.sortKeys(data);
        for (const i in sortedKeys) {
            const cat = sortedKeys[i];
            dataOptions.push(
                { text: cat.toLocaleUpperCase(), id: cat },
            );
        }
        let sel = `${this.props.selected}`;
        if (Utils.isNotSet(sel)) {
            sel = `${Object.keys(this.props.categories)[0]}`;
        }
        sel = sel.toLocaleUpperCase();
        const options = {
            minimumResultsForSearch: Infinity,
            placeholder: sel,
        };
        return (
            <div className='select-wrap category-dropdown'>
                <Select2
                    options={options}
                    className='select-box'
                    data={dataOptions}
                    onChange={this.setCategories}
                    id='categoryInput'
                />
            </div>
        );
    }

    /**
     * Category dropdown event handler
     *
     * @param e
     */
    setCategories(e) {
        const cats = $(e.target).val();
        if (Utils.isSet(cats)) {
            this.props.setStateFromHeader('categories', cats);
        }
    }

    /**
     * Epoch dropdown event handler
     *
     * @param e
     */
    setEpoch(e) {
        const epoch = e.target.value * 3600;
        this.props.setStateFromHeader('epoch', epoch);
    }

    /**
     * Slave dropdown event handler
     *
     * @param e
     */
    setSlaves(e) {
        this.props.setStateFromHeader('slave', e.target.value);
    }

    /**
     * Incident type dropdown event handler
     *
     * @param e
     */
    setType(e) {
        this.props.setStateFromHeader('type', e.target.value);
    }

    /**
     *
     */
    refresh() {
        this.props.setStateFromHeader('refresh');
        $('.fa-refresh').css('color', 'red');
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.categories != nextProps.categories || this.props.slaveList != nextProps.slaveList;
    }

    render() {
        return (
            <div className='control-header'>
                <span className='control-title'>Control panel</span>
                { this.getDropdownCategories() }
                { this.createDropdownType() }
                { this.createDropdownSlaves() }
                { this.createDropdownTime() }
                <a className='refresh fa fa-refresh' onClick={this.refresh} />
            </div>
        );
    }
}

export default DashboardControlHeader;
