/* eslint-disable */
import React from 'react';
import Utils from '../../js/utils.js';
import DashboardSection from '../dashboardSection/dashboardSection.jsx';
import DashboardControlHeader from '../dashboardSection/dashboardHeader.jsx';
import Loader from '../graph/loader.jsx';

class MainPanelDashboard extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.getData = this.getData.bind(this);
        this.loadThresholds = this.loadThresholds.bind(this);
        this.getThresholds = this.getThresholds.bind(this);
        this.setStateFromHeader = this.setStateFromHeader.bind(this);
        this.processData = this.processData.bind(this);
        this.setSelectedIndex = this.setSelectedIndex.bind(this);
        this.getDisplayData = this.getDisplayData.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        const data = this.selectInitialCategory(this.props.categories);
        const cat = Utils.isSet(data) ? Object.keys(data)[0] : '';
        return {
            data: [],
            catThresholds: {},
            selectedIndex: NaN,
            sortAttributes: {
                selectedGroup: cat,
                epoch: 86400,
                endpoint: 0,
                incidentType: 'ALL',
                slave: 'ALL',
            },
        };
    }

    /**
     * Select the initial category for the first ajax call.
     *
     * @returns {*}
     */
    selectInitialCategory(data) {
        data = JSON.parse(JSON.stringify(data));
        if (Utils.isNotSet(data)) return '';
        delete data.I_P_S;
        delete data.FAVOURITES;
        return Object.keys(data).length > 0 ? data : [''];
    }

    getData(epoch, category = this.state.sortAttributes.selectedGroup, catThresh = this.state.catThresholds) {
        category = category.toLowerCase();
        if (category.length > 0 && Utils.isSet(catThresh)) {
            const now = Utils.getTimeNow();
            let thresholds = this.getThresholds(category, catThresh);
            thresholds = thresholds.toString().replace(/,/g, ';');
            const url = `/dash/${category}/${thresholds}/${now - epoch}/${now}`;
            // ----------------- API CALL GET -----------------
            const scope = this;
            const successCallback = function (data) {
                data = scope.processData(data);
                const actualState = scope.state;
                actualState.sortAttributes.selectedGroup = category;
                actualState.sortAttributes.epoch = epoch;
                actualState.sortAttributes.endpoint = now;
                actualState.data = data;
                scope.setState(actualState);
            };
            const data = Utils.formApiData(url, true, 'json', successCallback);
            Utils.performGETcall(data);
        }
    }

    loadThresholds() {
    // ----------------- API CALL GET -----------------
        const scope = this;
        const successCallback = function (data) {
            scope.setState({
                catThresholds: data.category_thresholds,
            });
        };
        const data = Utils.formApiData('settings', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    getThresholds(cat = this.state.sortAttributes.selectedGroup, data = this.state.catThresholds) {
        let thresh = [30, 1.5, 20, 40, 50, 500];
        if (Utils.isSet(data)) {
            if (Utils.isSet(data[cat])) {
                thresh = data[cat];
            } else if (Utils.isSet(data.default)) {
                thresh = data.default;
            }
        }
        return thresh;
    }

    /**
     * Crossroad switch handling important data changes triggered from header.
     *
     * @param type
     * @param data
     */
    setStateFromHeader(type, data) {
        $('.sk-fading-circle').css('display', 'inline-block');
        switch (type) {
        case 'categories':
            this.getData(this.state.sortAttributes.epoch, data);
            break;
        case 'epoch':
            this.getData(data);
            break;
        case 'slave':
            const newstate = this.state.sortAttributes;
            newstate.slave = data;
            this.setState({ sortAttributes: newstate });
            break;
        case 'type':
            const newType = this.state.sortAttributes;
            newType.incidentType = data;
            this.setState({ sortAttributes: newType });
            break;
        case 'refresh':
            this.getData(this.state.sortAttributes.epoch, this.state.sortAttributes.selectedGroup);
            break;
        }
    }

    processData(data) {
        const entryList = [];
        if (Utils.isSet(data) && data.result != 'error') {
            for (const index in data) {
                const name = index.split('@');
                entryList.push({
                    ip: name[0],
                    alias: name[1],
                    length: data[index].list.length,
                    data: data[index].list,
                    counts: { PL: data[index].plIncidentCount, LAT: data[index].latIncidentCount },
                });
            }
        }
        return entryList;
    }

    setSelectedIndex(e) {
        let id = Number($(e.target).parent('tr').attr('data-id'));
        id = id == this.state.selectedIndex ? NaN : id;
        this.setState({
            selectedIndex: id,
        });
    }

    getDisplayData() {
        const { data } = this.state;
        const entry = [];
        if (Utils.isSet(data)) {
            let pushIndex = 0;
            for (const index in data) {
                const temp = data[index];
                const type = this.state.sortAttributes.incidentType;
                const isCorrectType = (type == 'ALL')
                    || (type == 'PL' && temp.counts.PL > 0)
                    || (type == 'LAT' && temp.counts.LAT > 0);
                if (isCorrectType
                    && (this.state.sortAttributes.slave == 'ALL' || this.state.sortAttributes.slave == temp.alias)) {
                    entry.push(
                        <tr key={pushIndex} onClick={this.setSelectedIndex} data-id={pushIndex}>
                            <td className='server-name'>{this.props.getTitle(this.props.config, temp.ip)}</td>
                            <td className='slave-name'>{`${temp.alias} : ${this.props.slaveList[temp.alias]}`}</td>
                            <td className='inc-count'>{temp.length}</td>
                        </tr>,
                    );
                    if (this.state.selectedIndex == pushIndex) {
                        entry.push(
                            <tr className='no-hover' key={`${pushIndex}s`}>
                                <td colSpan='4'>
                                    <DashboardSection
                                        data={temp.data}
                                        sortAttributes={this.state.sortAttributes}
                                        {...this.props}
                                    />
                                </td>
                            </tr>,
                        );
                    }
                }
                pushIndex++;
            }
        }
        return entry;
    }

    componentWillMount() {
        Utils.setHashSilently('dashboard');
        this.loadThresholds();
    }

    componentDidUpdate() {
        $('.fa-refresh').css('color', 'white');
        $('.sk-fading-circle').css('display', 'none');
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.catThresholds != nextState.catThresholds) {
            let cat = nextState.sortAttributes.selectedGroup;
            if (Utils.isNotSet(cat)) {
                if (Utils.isSet(nextProps.categories)) {
                    const data = this.selectInitialCategory(nextProps.categories);
                    cat = Utils.isSet(data) ? Object.keys(data)[0] : '';
                }
            }
            this.getData(86400, cat, nextState.catThresholds);
            return true;
        }
        if (this.props.categories != nextProps.categories) {
            if (Utils.isSet(nextState.catThresholds)) {
                const data = this.selectInitialCategory(nextProps.categories);
                const category = Utils.isSet(data) ? Object.keys(data)[0] : '';
                this.getData(nextState.sortAttributes.epoch, category, nextState.catThresholds);
            }
            return true;
        }
        return nextState != this.state;
    }

    render() {
        const thresholds = this.getThresholds();
        const count = this.state.data.length;
        return (
            <div className='main-panel'>
                <div id='dashboard'>
                    <div className='dashboard-body'>
                        <div className='dashboard-section'>
                            <div className='dashboard-header'>
                                <Loader />
                            </div>
                            <DashboardControlHeader
                                setStateFromHeader={this.setStateFromHeader}
                                slaveList={this.props.slaveList}
                                categories={this.selectInitialCategory(this.props.categories)}
                                group={this.state.sortAttributes.selectedGroup}
                                selected={this.state.sortAttributes.selectedGroup}
                            />
                            <div className='threshold-line'>
                                <span className='line'>
category thresholds
                                    <span className='desc'>[change, minimal change, threshold] </span>
                                </span>
                                <span className='line left-pad'>
                                    latency:
                                    <span className='desc'>
                                        [
                                        {' '}
                                        {thresholds[0]}
,
                                        {' '}
                                        {thresholds[1]}
,
                                        {' '}
                                        {thresholds[2]}
                                        {' '}
]
                                    </span>
                                </span>
                                <span className='line left-pad'>
                                    packet loss:
                                    <span className='desc left-pad-15'>
                                        [
                                        {' '}
                                        {thresholds[3]}
,
                                        {' '}
                                        {thresholds[4]}
,
                                        {' '}
                                        {thresholds[5]}
                                        {' '}
]
                                    </span>
                                </span>
                            </div>
                            <div className='dashboard-table'>
                                { count > 0
                                    && (
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>IP</th>
                                                    <th>SLAVE : IP</th>
                                                    <th>COUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.getDisplayData() }
                                            </tbody>
                                        </table>
                                    )
                                }
                                { count == 0
                                    && <span className='nodata'> NO DATA </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainPanelDashboard;
