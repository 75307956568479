import React from 'react';
import Modal from 'react-modal';
import Utils from '../../js/utils.js';
import OperationConfirm from '../common/operationConfirm.jsx';
import Loader from '../graph/loader.jsx';

/** *
 * PROPS
 * redirect - [func_reference] - call after success-callback
 * openModal - [func_reference] - call when modal opens, to initialize custom content
 * closeModal - [func_reference] - call when modal closes
 * style - [obj] - css modal styling
 * Trigger - [jsx] - button that opens modal, graphically specific to a modal
 * modalID - [string] - 'modal-ID'
 * render - [func_reference] - outputs custom modal's content
 */

class ModalWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            modalIsOpen: false,
            showConfirm: false,
            confirmIsSuccess: false,
            showLoader: false,
            apiResponse: {},
        };
    }

    confirmCallback = (response) => {
        const bool = response.verdict;
        this.setState({
            showConfirm: true, showLoader: false, confirmIsSuccess: bool, apiResponse: response,
        });
    }

    successRedirect = () => {
        if (Utils.isSet(this.props.redirect)) this.props.redirect();
        if (this.props.dontClose) {
            this.setState({
                modalIsOpen: true,
                showConfirm: false,
                confirmIsSuccess: false,
                showLoader: false,
            });
        } else {
            this.closeModal();
        }
    }

    openModal = () => {
        if (Utils.isSet(this.props.openModal)) {
            this.props.openModal();
        }
        this.setState({ ...this.getInitialState(), modalIsOpen: true });
    }

    closeModal = () => {
        if (Utils.isSet(this.props.closeModal)) this.props.closeModal();
        this.setState({ ...this.getInitialState() });
    }

    showLoading = () => this.setState({ showLoader: true });

    getStyles() {
        const config = JSON.parse(JSON.stringify(Utils.modals.config));
        config.content = { ...config.content, ...this.props.style.content };
        return config;
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { Trigger } = this.props;
        let { customWrapClass } = this.props;
        customWrapClass = Utils.isSet(customWrapClass) ? customWrapClass : '';
        return (
            <div className={'modal-wrap ' + customWrapClass}>
                <Trigger action={this.openModal} />
                {this.state.modalIsOpen && (
                    <Modal isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal} style={this.getStyles()}>
                        <div className={'modal ' + this.props.modalID}>
                            <button type='button' onClick={this.closeModal} className='modal-close' />
                            <OperationConfirm
                                show={this.state.showConfirm}
                                isSuccess={this.state.confirmIsSuccess}
                                redirect={this.successRedirect}
                                errorPrompt={this.state.apiResponse.errorPrompt}
                                successPrompt={this.props.successPrompt}
                                closeConfirm={
                                    () => this.setState({
                                        modalIsOpen: true,
                                        showConfirm: false,
                                        confirmIsSuccess: false,
                                        showLoader: false,
                                    })
                                }
                            />
                            {this.props.render(this.confirmCallback, this.showLoading)}
                            { this.state.showLoader &&
                            <div className='modal-loader'>
                                <div className='position'>
                                    <Loader />
                                    <h3>Sending request..</h3>
                                </div>
                            </div> }
                            {this.props.showRequiredInfo &&
                            <span className='label-title'>
                                required values
                                <i className='fa fa-star' />
                            </span>
                            }
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

export default ModalWrapper;
