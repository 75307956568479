/* global $:true */
import React from 'react';
import Utils from '../../js/utils.js';

class SettingsGlobal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: Utils.getPropertyOfObject(['data', 'public_url'], this.props, ''),
            theme: Utils.getPropertyOfObject(['data', 'public_theme'], this.props, 'DARK').toUpperCase(),
            prompt: {},
        };
    }

    inputChange = (e, key) => this.setState({ [key]: e.target.value });

    settingsChange = () => {
        const callbacks = {};
        const succMsg = 'Settings change was successful';
        const errMsg = 'Sorry, settings change was not successful';
        callbacks.successCallback = succ => this.setState({ prompt: { isSuccess: true, msg: succMsg } });
        callbacks.errorCallback = err => this.setState({ prompt: { isSuccess: false, msg: errMsg } });
        this.props.setSettings({ public_url: this.state.url, public_theme: this.state.theme }, callbacks);
    }

    getSwitch() {
        const isLight = this.state.theme === 'LIGHT';
        return (
            <div className='switches'>
                <div className='switch'>
                    <span className={'white-text ' + (isLight ? 'active' : '')} onClick={() => this.setState({ theme: 'LIGHT' })}>
                        LIGHT
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={() => this.setState({ theme: isLight ? 'DARK' : 'LIGHT' })} checked={!isLight} />
                        <span className='slider round' />
                    </label>
                    <span className={'white-text ' + (isLight ? '' : 'active')} onClick={() => this.setState({ theme: 'DARK' })}>
                        DARK
                    </span>
                </div>
            </div>
        );
    }

    componentWillReceiveProps(nextProps) {
        if (Utils.isNotSet(this.props.data) && this.props.data != nextProps.data) {
            this.setState({
                url: Utils.getPropertyOfObject(['data', 'public_url'], nextProps, ''),
                theme: Utils.getPropertyOfObject(['data', 'public_theme', nextProps, 'DARK']),
            });
        }
    }

    render() {
        return (
            <div className='tab-content'>
                {Utils.isSet(this.state.prompt) &&
                <div className={'banner-wrap__banner banner-wrap__banner--' + (this.state.prompt.isSuccess ? 'success' : 'error')}>
                    <div className='banner-wrap__text-block'>
                        <span>{this.state.prompt.msg}</span>
                    </div>
                    <button type='button' onClick={() => this.setState({ prompt: {} })} />
                </div>
                }
                <span className='page-title'>
                    {this.props.name}
                </span>
                <div className='form-content-wrap'>
                    <div className='input-wrap'>
                        <label className='label-title'>
                            <span>PUBLIC SHARE URL:</span>
                        </label>
                        <input
                            type='text'
                            value={this.state.url}
                            onChange={e => this.inputChange(e, 'url')}
                            className='long-input'
                        />
                    </div>
                    <div className='input-wrap'>
                        <label className='label-title'>
                            <span>PUBLIC SHARE DEFAULT THEME:</span>
                        </label>
                        {this.getSwitch()}
                    </div>
                </div>
                <div className='butts'>
                    <button type='submit' className='butt-confirm' onClick={this.settingsChange}>Change Settings</button>
                </div>
            </div>
        );
    }
}

export default SettingsGlobal;
