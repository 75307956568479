import React from 'react';
import Utils from '../../js/utils.js';

class OperationConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { timeHide: false };
    }

    componentDidUpdate() {
        const scope = this;
        if (this.props.show && !this.state.timeHide) {
            const timeout = this.props.isSuccess ? 1500 : 20000;
            setTimeout(() => {
                if (scope.mounted) {
                    scope.setState({ timeHide: true });
                    scope.props.redirect();
                }
            }, timeout);
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.show && !nextProps.show) {
            this.setState({ timeHide: false });
        }
        return this.props != nextProps || this.state != nextState;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const succPrompt = Utils.getPropertyOfObject(['successPrompt'], this.props, 'Operation was successful');
        if (this.props.show && !this.state.timeHide) {
            return (
                <div className='confirm-dialog'>
                    <div className={'confirm-dialog__in' + (this.props.isSuccess ? ' success' : ' error')}>
                        { this.props.isSuccess && (
                            <h2>
                                <i className='fa fa-check-circle' />
                                {succPrompt}
                            </h2>
                        )}
                        { !this.props.isSuccess && (
                            <>
                                <h2>
                                    <i className='fa fa-times-circle' />
                                    Operation was not successful
                                </h2>
                                <>
                                    {Utils.isSet(this.props.errorPrompt) &&
                                    <span className='errPrompt'>
                                        error:
                                        {' '}
                                        { this.props.errorPrompt }
                                    </span>
                                    }
                                    <div className={'butts'}>
                                        <button type='button' onClick={this.props.closeConfirm} className='modal-confirm'>
                                            edit
                                        </button>
                                        <button type='button' onClick={this.props.redirect} className='modal-confirm'>close</button>
                                    </div>
                                </>
                            </>
                        )}
                    </div>
                </div>
            );
        }
        return null;
    }
}

export default OperationConfirm;
