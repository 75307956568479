import React from 'react';
import Utils from '../../js/utils';

class Banner extends React.Component {
    getLicenseError = (ipLimitExceeded, slaveLimitExceeded) => {
        const { license } = this.props;
        if (ipLimitExceeded) {
            return this.wrapErrorBanner(0, {
                title: 'IP count exceeded',
                message: `Maximum allowed IP count is ${license.pingIP.soft} Your count is ${license.pingIP.used}`,
                recommendation: 'Please reduce your IP count or contact our support team for license upgrade',
            });
            // mess +='Maximum allowed IP count is ' + license.pingIP.soft + '. Your count is '+license.pingIP.used;
        }
        if (slaveLimitExceeded) {
            return this.wrapErrorBanner(1, {
                title: 'Probe count exceeded',
                message: `Maximum allowed probe count is ${license.slave.soft} Your count is ${license.slave.used}`,
                recommendation: 'Please reduce your probe count or contact our support team for license upgrade',
            });
            // mess +='Maximum allowed slave count is ' + license.slave.soft + '. Your count is '+license.slave.used;
        }
        return this.wrapErrorBanner(2, {
            title: 'License soft limits exceeded',
            message: '',
            recommendation: 'Please contact our support team for license consultation',
        });
    }

    wrapErrorBanner = (key, mess) => (
        <div key={key} className='banner-wrap__banner banner-wrap__banner--error'>
            <div className='banner-wrap__text-block'>
                <span>
                    <strong className='title'>
                        {mess.title}
                    </strong>
                    <span>{mess.message}</span>
                </span>
                <span>{mess.recommendation}</span>
            </div>
            <button type='button' onClick={() => this.props.hideBanners()} />
        </div>
    )

    render() {
        const { license } = this.props;
        const ipLimitExceeded = license.pingIP.used > license.pingIP.soft;
        const slaveLimitExceeded = license.slave.used > license.slave.soft;
        const banners = this.getLicenseError(ipLimitExceeded, slaveLimitExceeded);
        return (
            <div className='banner-wrap'>
                {banners}
            </div>
        );
    }
}

export default Banner;
