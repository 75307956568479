import React from 'react';
import Utils from '../../js/utils.js';
import TraceTable from '../graph/footer/traceTable.jsx';

class MainPanelTrace extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.loadData = this.loadData.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.getBack = this.getBack.bind(this);
        this.getTraces = this.getTraces.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = { traceData: {} };
    }

    loadData(ip) {
    // ----------------- API CALL GET -----------------
        const scope = this;
        const url = `/trace/${ip}/slave=${this.props.activeSlave}`;
        const successCallback = function (data) {
            scope.setState({
                traceData: data,
            });
        };
        const data = Utils.formApiData(url, true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    getTitle() {
        return `${this.props.selectedIP} ${this.props.ipName}`;
    }

    getBack() {
        this.props.setView('detail');
    }

    getTraces() {
        const traces = [];
        const data = this.state.traceData;
        for (const trace in data) {
            traces.push(
                <TraceTable data={data[trace]} key={trace} />,
            );
        }
        return traces;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.traceData != nextState.traceData) {
            return true;
        }
        return false;
    }

    componentWillMount() {
        this.loadData(this.props.selectedIP);
    }

    render() {
        return (
            <div className='main-panel'>
                <div className='traceRoute-wrap'>
                    <a className='back' onClick={this.getBack}>
                        <i className='fa fa-chevron-left' />
                        BACK
                    </a>
                    <div className='trace-header'>
                        <h1>Traceroute</h1>
                        <h2>{this.getTitle()}</h2>
                    </div>
                    {this.getTraces()}
                </div>
            </div>
        );
    }
}

export default MainPanelTrace;
