import { isInSubnet } from 'is-in-subnet';
import Utils from '../../../js/utils';

const Filter = {

    filter(key, value, data) {
        switch (key) {
        case 'byString':
            return this.byString(value, data);
        case 'bySlave':
            return this.bySlave(value, data);
        case 'byAS':
            return this.byAS(value, data);
        case 'bySubnet':
            return this.bySubnet(value, data);
        default:
            return this.byString(value, data);
        }
    },

    byAS(str, data) {
        const keyword = str.toString().toUpperCase();
        let as = keyword;
        let slaves = '';
        if (keyword.includes('|')) { // search by slaves
            const arr = keyword.split('|');
            as = arr[0].trim();
            slaves = arr[1].trim();
        }
        const asNum = as.substring(2);
        const filtered = data.filter((obj) => {
            const { as, alias } = obj;
            const ipAS = Utils.isSet(as) ? as.toString() : '';
            const ipAlias = Utils.isSet(alias) ? alias.toString().toLowerCase() : '';
            return ipAS.startsWith(asNum) || ipAlias.includes('as' + asNum);
        });

        if (Utils.isSet(slaves)) {
            return this.bySlave(slaves, filtered);
        }
        return filtered;
    },

    bySlave(slavesStr, data) {
        const slaveArr = slavesStr.split(',');
        if (slaveArr.length > 1) { // multiple slaves as1234:ams,mia
            return data.filter((obj) => {
                const { slaves } = obj;
                let validity = true;
                slaveArr.forEach((slave) => {
                    if (!slaves.includes(slave)) { validity = false; }
                });
                return validity;
            });
        } else { // only one slave as1234:ams
            return data.filter((obj) => {
                const { slaves } = obj;
                const ipSlaves = Utils.isSet(slaves) ? slaves.toString().toUpperCase() : '';
                return ipSlaves.includes(slavesStr);
            });
        }
    },

    byString(str, data) {
        let keyword = str.toString().toUpperCase();
        let slaves = '';
        if (keyword.includes('|')) { // search by slaves
            const arr = keyword.split('|');
            keyword = arr[0].trim().toUpperCase();
            slaves = arr[1].trim();
        }
        const filtered = data.filter((obj) => {
            let { ip, alias } = obj;
            alias = alias.toString().toUpperCase();
            return ip.includes(keyword) || alias.includes(keyword);
        });
        if (Utils.isSet(slaves)) {
            return this.bySlave(slaves, filtered);
        }
        return filtered;
    },

    bySubnet(subnet, data) {
        return data.filter((obj) => {
            const { ip } = obj;
            return isInSubnet(ip, subnet);
        });
    },

    filterLiveIPs(data, colorList) {
        const results = data.filter((obj) => {
            const { ip } = obj;
            let ipLoss = colorList[ip];
            if (Utils.isSet(ipLoss)) {
                ipLoss = ipLoss.AVERAGE;
                return ipLoss < 51;
            }
            return false;
        });
        return results;
    }
};

export default Filter;
