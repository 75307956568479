import React from 'react';
import SVGInline from 'react-svg-inline';
import { DropTarget } from 'react-dnd';
import Utils from '../../js/utils.js';
import IP from '../sidePanel/sidebar/ip.jsx';
import iconChevronDown from '../../../less/libs/icons/chevron_down.svg';

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    };
}

const boxTarget = {
    drop(props) {
        return { };
    },
};

/**
 * PROPS
 *
 * addValToQuickList - [func[ - function to add values into quick-list
 * list - [arr] - quicklist content
 * handleDetailSwitch - [func] -
 * isOver - [bool] - cursor is over
 */

class QuickList extends React.Component {
    constructor(props) {
        super(props);
        this.getLength = this.getLength.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = { isOpen: false, count: 0 };
    }

    openPanel = () => {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }

    deleteCat = (e) => {
        const catName = e.target.getAttribute('data');
        this.props.addValToQuickList({ isIp: false, name: catName });
    }

    getIPs = (data) => {
        if (Utils.isSet(data)) {
            const keys = Object.keys(data).sort();
            return Utils.reSortValuesByKey(data, keys);
        }
        return [];
    }

    getContent = () => {
        const data = this.props.list;
        const outList = [];
        for (const index in data) {
            const temp = data[index];
            if (temp.isIp) {
                outList.push(
                    <IP
                        key={temp.ip}
                        ip={temp.ip}
                        title={temp.title}
                        color={temp.data.color}
                        slave={temp.data.slave}
                        defaultColor={temp.data.defaultColor}
                        updateSelectedIp={temp.data.updateSelectedIp}
                        addValToQuickList={temp.data.addValToQuickList}
                    />,
                );
            } else {
                const locData = this.getIPs(temp['data-ip']);
                outList.push(
                    <li key={temp.name} className='cat-li'>
                        <span className='section'>
                            <i className='fa fa-list-ul' />
                            <span className='title'>{temp.name}</span>
                            <a data={temp.name} onClick={this.deleteCat} className='fa fa-minus' />
                        </span>
                        { locData.length > 0 && <ul>{ locData }</ul> }
                        { locData.length === 0 && <span className='noData'>No data</span>}
                    </li>,
                );
            }
        }
        return outList;
    }

    getLength(props) {
        const data = props.list;
        let count = 0;
        for (const index in data) {
            if (data[index].isIp) {
                count++;
            } else {
                count = 0;
                break;
            }
        }
        if (count === 0 && data.length === 1 && !data[0].isIp) {
            const dataIp = Utils.getPropertyOfObject([0, 'data-ip'], data, []);
            count = dataIp.length;
        }
        this.setState({
            count,
        });
    }

    showCompare() {
        return this.state.isOpen && this.state.count > 1 && this.state.count < 3;
    }

    componentWillMount() {
        this.getLength(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.getLength(nextProps);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.list != this.props.list || this.state != nextState;
    }

    render() {
        const { connectDropTarget, isOver } = this.props;
        const classname = `${this.state.isOpen ? 'open' : 'close'}`;
        const direction = this.state.isOpen ? 'down' : 'up';
        const header = `QUICKLIST${this.state.count > 0 ? `   [${this.state.count}]` : ''}`;
        const listLength = Utils.getPropertyOfObject(['list'], this.props, []).length;
        return connectDropTarget(
            <div id='quickList' className={classname + (isOver ? ' isOver' : '')}>
                <div className='controls'>
                    <a className='trigger' onClick={this.openPanel}>
                        <span>{header}</span>
                        <span className={`custom-bubble ${this.state.isOpen ? 'active' : ''}`}>
                            {listLength > 0 ? listLength : ''}
                            <SVGInline svg={iconChevronDown} className={`chevron ${direction}`} />
                        </span>
                    </a>
                    { listLength > 0 &&
                        <a onClick={this.props.deleteQuickList} className='quick__delete-all'>
                            <i className='fa fa-trash' />
                        </a>
                    }
                </div>
                <div className={`content${this.props.isOver ? ' add' : ''}`}>
                    {listLength > 0
                        && (
                            <ul className='ip-container'>
                                {this.getContent()}
                            </ul>
                        )
                    }
                    {listLength <= 0 &&
                    <p className='quick__description'>
                        Use quicklist for easy&fast access to IPs you are working with.
                        <br />
                        <br />
                        Simply drag&drop IP or category from the section below.
                    </p>
                    }
                </div>
            </div>,
        );
    }
}

export default DropTarget(['ip', 'cat'], boxTarget, collect)(QuickList);
