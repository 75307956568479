import React from 'react';
import Select2 from 'react-select2-wrapper';
import AddValModal from '../modals/addValModal.jsx';
import Utils from '../../js/utils.js';

/**
 * PROPS
 * selection - [arr] - ['food','fruit','apple'] - list of categories and subcategories (left to right)
 * dataOptions - [arr] - [{text: "-", id: "-"}, {text: "SUB-FIRST", id: "SUB_FIRST"}, ...] - list of options
 * setNaviState - [func] - sets current cat selection in parent component - categorySelection
 *
 */
class Navigator extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getNavigatorItems = this.getNavigatorItems.bind(this);
        this.navigatorClick = this.navigatorClick.bind(this);
        this.navigatorReset = this.navigatorReset.bind(this);
        this.setValue = this.setValue.bind(this);
    }

    getNavigatorItems() {
        const data = [...this.props.selection];
        const navigatorContent = [];
        navigatorContent.push(
            <li key='special'>
                <a onClick={this.navigatorReset}><span className='fa fa-chevron-left' /></a>
            </li>,
        );
        data.forEach((key) => {
            if (Utils.isSet(key)) {
                navigatorContent.push(
                    <li key={key}>
                        <a className='no-effect' onClick={() => this.navigatorClick(key)}>
                            {key.toString().toUpperCase()}
                        </a>
                        <i className='fa fa-chevron-right' />
                    </li>,
                );
            }
        });
        navigatorContent.push(
            <Select2
                key='select-add'
                className='category-select'
                data={this.props.dataOptions}
                onSelect={e => this.setValue(e.target.value)}
                defaultValue='-'
                options={{ minimumResultsForSearch: 'Infinity' }}
            />,
        );
        navigatorContent.push(
            <AddValModal key='add' valType='category' setValue={this.setValue} />,
        );
        return navigatorContent;
    }

    navigatorReset() {
        this.props.setNaviState([]);
    }

    navigatorClick(categoryKey) {
        const data = this.props.selection;
        const index = data.indexOf(categoryKey); // find the index of the category and use only categories before
        const newCategories = data.slice(0, index + 1); // +1 to only remove categories that follow the action trigger
        // example categories: one > one-one > one-one-one > one-one-one-one (user clicks 'one-one')
        // new categories are: one > one-one ('>' means following string is sub-category)
        this.props.setNaviState(newCategories);
    }

    setValue(name) {
        if (name != '-') {
            const navigatorContent = [...this.props.selection, name];
            this.props.setNaviState(navigatorContent);
        }
    }

    render() {
        return (
            <ul className='navigator'>
                {this.getNavigatorItems()}
            </ul>
        );
    }
}

export default Navigator;
