/* global $:true */

import React from 'react';
import SVGInline from 'react-svg-inline';
import { DragSource } from 'react-dnd';
import Utils from '../../../js/utils.js';
import CategorySettingsModal from '../../modals/categorySettings.modal.jsx';
import iconChevronDown from '../../../../less/libs/icons/chevron_down.svg';

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
    };
}

const catSource = {
    beginDrag(props) {
        return {
            name: props.name,
        };
    },

    endDrag(props, monitor) {
        const item = monitor.getItem();
        const dropResult = monitor.getDropResult();

        if (dropResult) {
            const dataIp = props.ipList;
            props.addValToQuickList({
                isIp: false, name: props.name, data: props, 'data-ip': dataIp,
            });
        }
    },
};


/**
 * PROPS
 *
 * connectDragSource - [func], isDragging - [func],
 * lossList - [arr] - e.g.{AMS: "185.102.218.80:4458", ATL: "185.152.66.193:4458", NYC: "195.181.169.88:4458", PRG-DEFAULT: "127.0.0.1:4458"}
 * subContent - [obj] - list of sub-categories and IPs
 e.g. - "{"I_P_S":{"one-two":{"key":"1.1.1.2","ref":null,"props":{"ip":"1.1.1.2","title":"one-two","color":"#00B851"},"_owner":null,"_store":{}}},"C_A_T":{}}"
 * ipList - [obj] - list of IPs
 * name - [string] - category name
 * path - [arr] - array of strings of parent categories
 * showAutoGroupSettings - [bool] - bool if autogroup-settings should be display in the modal
 * showSettings - [bool] - bool if settings cog should be display
 * reloadConfig - [func] - reloads the main config and updates page
 * size - [num] - number of sub IPs+cats
 * data - [obj] - data from config
 * slaveList - [] -
 *
 */

class Category extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.click = this.click.bind(this);
        this.createStats = this.createStats.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.getContent = this.getContent.bind(this);
        this.getHighestLossColor = this.getHighestLossColor.bind(this);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            isOpen: false,
            stats: this.createStats(this.props.lossList),
        };
    }

    click() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    createStats(data) {
        const stats = {
            0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0,
        };
        for (const key in data) {
            const { loss } = data[key];
            let colorIndex = 0;
            if (loss.toFixed(1) > 0.1) {
                colorIndex = Math.ceil(loss / 10);
                colorIndex = colorIndex > 6 ? 6 : colorIndex;
            }
            stats[colorIndex] += 1;
        }
        return stats;
    }

    sortCategoryKeys(list) {
        const sorted = Utils.sortKeys(list);
        const indexesToRemove = ['I_P_S'];
        for (const i in indexesToRemove) {
            const index = sorted.indexOf(indexesToRemove[i]);
            if (index !== -1) sorted.splice(index, 1);
        }
        sorted.push('I_P_S');
        return sorted;
    }

    getCategories(data) {
        const keys = this.sortCategoryKeys(data);
        return Utils.reSortValuesByKey(data, keys);
    }

    getIPs(data) {
        const keys = Object.keys(data).sort();
        return Utils.reSortValuesByKey(data, keys);
    }

    getContent() {
        const catData = this.props.subContent.C_A_T;
        const getCats = Utils.isSet(catData);
        const getIPs = Utils.isSet(this.props.ipList);
        return (
            <ul className='category-content'>
                { getCats && this.getCategories(catData) }
                { getIPs && this.getIPs(this.props.ipList)}
            </ul>
        );
    }

    getHighestLossColor() {
        const data = this.state.stats;
        for (let i = 6; i >= 0; i--) {
            if (data[i] > 0) {
                return Chart.colorField[i];
            }
        }
        return Chart.colorField[0];
    }

    getCategoryMark = () => ({ fill: this.getHighestLossColor() });

    render() {
        const { connectDragSource } = this.props;
        const { isDragging } = this.props;
        const dragStyle = {
            opacity: isDragging ? 0.5 : 1,
            cursor: 'move',
            border: isDragging ? 'solid 1px red' : 'none',
        };
        const direction = this.state.isOpen ? 'down' : 'up';
        const doNotShowSettings = ['I_P_S', 'FAVOURITES', '__REPORT'];
        const showSettings = this.props.showSettings && !doNotShowSettings.includes(this.props.name.toUpperCase());
        return connectDragSource(
            <li style={dragStyle} className={this.state.isOpen ? 'active' : ''}>
                <div className='first-line'>
                    {showSettings
                    && (
                        <CategorySettingsModal
                            category={this.props.name}
                            path={this.props.path}
                            slaveList={this.props.slaveList}
                            reloadConfig={this.props.reloadConfig}
                            isNewCategory={false}
                            slaveSources={this.props.slaveSources}
                        />
                    )
                    }
                    <a className='category-section-name' onClick={this.click}>
                        <span className='section'>
                            {/* <i className="fa fa-list-ul"/> */}
                            <span className='title'>
                                {this.props.name}
                                <svg>
                                    <circle cx='5' cy='5' r='3' style={this.getCategoryMark()} />
                                </svg>
                            </span>
                            <span className={`right-bubble ${this.state.isOpen ? 'active' : ''}`}>
                                {this.props.size}
                                <SVGInline svg={iconChevronDown} className={`chevron ${direction}`} />
                            </span>
                        </span>
                    </a>
                </div>
                {this.state.isOpen && this.getContent()}
            </li>,
        );
    }
}

export default DragSource('cat', catSource, collect)(Category);
