/* global $:true */

import React from 'react';
import SVGInline from 'react-svg-inline';
import Utils from '../../js/utils.js';
import iconMonitoring from '../../../less/libs/icons/icon-monitoring.svg';

class PagePicker extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getSmallMenu = this.getSmallMenu.bind(this);
        this.getMenu = this.getMenu.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            showMenu: false,
            menu: {
                Monitoring: {
                    logo: <SVGInline svg={iconMonitoring} className='icon-monitoring' />,
                    viewName: 'detail',
                    status: 'visible',
                },
                Dashboard: {
                    logo: 'fa-list',
                    viewName: 'dashboard',
                    status: 'hidden',
                },
                Settings: {
                    logo: <i className='fa fa-cog' />,
                    viewName: 'settings',
                    status: 'visible',
                },

            },
        };
    }

    handleClick(event) {
        const view = $(event.target).attr('data-view');
        this.props.setStateView(view);
    }

    getSmallMenu() {
        const listItems = [];
        if (this.props.view === 'settings') {
            listItems.push(
                <li key={1} className='option '>
                    <i className='fa fa-home' />
                    <a data-view='detail' onClick={this.handleClick}>Monitoring</a>
                </li>,
            );
        } else {
            listItems.push(
                <li key={0} className='option '>
                    <i className='fa fa-cog' />
                    <a data-view='settings' onClick={this.handleClick}>Settings</a>
                </li>,
            );
        }
        return (
            <ul>
                {listItems}
                <li key='sign-out'>
                    <a onClick={Utils.handleLogout}><i className='fa fa-sign-out' /></a>
                </li>
            </ul>
        );
    }

    getMenu() {
        return this.getSmallMenu();
        // temporary until there are more options
        const { menu } = this.state;
        const { view } = this.props;
        const liItems = [];
        for (const index in menu) {
            const item = menu[index];
            if (item.status === 'visible') {
                const isActive = view.includes(item.viewName) ? 'active' : '';
                liItems.push(
                    <li key={index} className={`option ${isActive}`}>
                        {item.logo}
                        <a data-view={item.viewName} onClick={this.handleClick}>
                            {index}
                        </a>
                    </li>,
                );
            }
        }
        return (
            <ul>
                {liItems}
                <li key='sign-out'>
                    <a onClick={Utils.handleLogout}><i className='fa fa-sign-out' /></a>
                </li>
            </ul>
        );
    }

    render() {
        return (
            <div className='page-picker'>
                {this.getMenu()}
            </div>
        );
    }
}

export default PagePicker;
